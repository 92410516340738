import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ProdBloc from './ProdBloc'
import MapPopup from './MapPopup'
import osmaps from '../functions/osmaps.js'

class MapProducteur extends Component {

  constructor(props) {
    super(props);
    this.state = {
      map: null,
      markers: [],
      currentMarkers: [],
      adresses: [],
      selectedMarker: null,
      popupContent: null
    }

    this.mapmove_type = 0
  }

  static getDerivedStateFromProps(newProps, prevState) {
    return null
  }

  async componentDidMount() {
    console.log("MAPPROD DIDMOUNT")

    const adresses = this.props

    const renderedMap = await osmaps.renderMapProducteur(this, adresses)

    if ( renderedMap ) {

      this.setState({
        map: renderedMap.map,
        markers: renderedMap.markers,
        currentMarkers: renderedMap.currentMarkers,
        adresses: adresses,
        mapLoading: false
      })
    }

    return null
  }

  componentDidUpdate(newProps, prevState) {
    return null
  }

  displayPopup(map, marker_feature) {
    const popupState = osmaps.showPopup(map, marker_feature) // marker_id, active, content
    this.setState({selectedMarker: popupState.marker_id, popupContent: popupState.content })
  }

  closePopup(e) {
    console.log("closePopup")
    osmaps.hidePopup()
    this.setState({active: false, selectedMarker: null })
  }

  render() {
    console.log("MAPROD RENDER")

    const { map,
            mapLoading,
            currentMarkers,
            selectedMarker,
            popupContent } = this.state

    console.log(this.state)

    return (
      <div id="osmap" className="fullGrid enter">
        <div className="map_container">
          <div id="map" className="map"></div>
          <MapPopup
            key='mapopup'
            active={selectedMarker ? true : false}
            linkedMarker={selectedMarker}
            content={popupContent}
            index='mapopup'
            onClick={e => this.closePopup()}
            />
        </div>
        <div className="listprods">
          { !currentMarkers ?
            "Liste des producteurs..."
            :
            currentMarkers.map(marker => {
              const param_obj = {map: map, marker_feature: marker}
              return <ProdBloc
                        key={`prodbloc-${marker.values_.id}`}
                        active={(selectedMarker===marker.values_.id) ? true : false}
                        onClick={e => this.displayPopup(map, marker)}
                        {...param_obj} />
            })
          }
        </div>
      </div>
    )
  }
}

export default MapProducteur
