// Librairies React
import React, { useState, useEffect, useRef } from 'react'
import HTMLink from './HTMLink'
import PropTypes from 'prop-types'
import Slider from 'react-slick'
import Icon from './Icon'
import parse from 'html-react-parser'

// Composant
export default function PopinPhotos(props) {

  const sliderRef = useRef(null)

  const [state, setState] = useState({
    active: false,
    settings: {
      dots: true,
      arrows: false,
      infinite: true,
      initialSlide: props.index,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      onInit: () => {
        //this.refreshSlider()
      },
      onReInit: () => {
        //this.refreshSlider()
      }
    }
  })

  useEffect( () => {
    if ( state.settings.initialSlide !== props.index ) {
      console.log("updatePopin", props.index)
      sliderRef.current.slickGoTo(props.index, true)
    }
  }, [props])

  const gotoPrev = (e) => {
    sliderRef.current.slickPrev()
  }

  const gotoNext = (e) => {
    sliderRef.current.slickNext()
  }

  return (
    <div className={`popin popin-slider ${props.display ? 'active' : ''}`}>
      <div className='popin-container'>
        <Slider ref={sliderRef} {...state.settings}>
          {props.children.map( (child, i) =>
            <div key={i} className='item'>
              {child}
            </div>
          )}
        </Slider>

        <button className="arrow-prev" onClick={(e) => gotoPrev(e)}>
          <Icon icon='fleche' />
        </button>

        <button className="arrow-next" onClick={(e) => gotoNext(e)}>
          <Icon icon='fleche' />
        </button>
      </div>

      <button className="btn-close" onClick={(e) => props.onClick(e)}>
        <Icon icon='close' />
      </button>

      <div className='popin-overlay'></div>
    </div>
  )
}
