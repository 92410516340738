import React, { Fragment, Component } from 'react'
import PropTypes from 'prop-types'
import SwitchField from '../components/SwitchField'
import PopinPage from '../components/PopinPage'
import functions from '../functions/functions.js'

// Définition des champs
const objets = [
  "Demande simple",
  "Remerciement",
  "Correction d'informations sur le site"
]
// Tableau des champs par étape
const etapes = [
  // 1 seule étape sur le formulaire
  { step: 1,
    fields: [
      { name: "nom",
        type: "text",
        classe: "",
        label: "Nom",
        placeholder: "Saisissez votre nom",
        listError: "Votre nom",
        required: true,
      },
      { name: "prenom",
        type: "text",
        classe: "",
        label: "Prénom",
        placeholder: "Saisissez votre prénom",
        listError: "Votre prénom",
        required: true,
      },
      { name: "email",
        type: "email",
        classe: "",
        label: "Email",
        placeholder: "Saisissez votre email",
        listError: "Votre email",
        required: true,
      },
      { name: "objet",
        type: "select",
        classe: "grid100",
        label: "Objet",
        placeholder: "Précisez l'objet de votre contact",
        listError: "L'objet de votre demande",
        required: true,
        options: objets
      },
      { name: "message",
        type: "textarea",
        classe: "grid100",
        label: "Message",
        placeholder: "Saisissez votre message",
        listError: "Votre message",
        required: true
      },
      { name: "rgpd",
        classe: "grid100",
        link: "page-d-exemple",
        listError: "L'acceptation de notre politique de confidentialité",
        required: true
      }
    ]
  }
]


// Initialise les champs si localStorage existe
const formdatas = (
  localStorage.getItem('Contact')
  ?
  JSON.parse(localStorage.getItem('Contact'))
  :
  {
    nom: "",
    prenom: "",
    email: "",
    objet: "",
    message: "",
    rgpd: false
  }
)


class ContactForm extends Component {

  constructor(props) {
    super(props);
    this.state = {
      form: "Contact",
      fields: formdatas,
      focused: "", // Champ courant ayant le focus
      status: '', // Statut du formulaire : vide, succes ou error
      popin: {
        content: '', // Contenu de la popin
        display: false // Affichage de la popin
      },
      fieldErrors: {}, // Liste des champs en erreur
      returnMessage: '', // Message d'erreur
      isLoading: false
    }

    // Controller du fetch
    this.controller = new window.AbortController()
  }

  static getDerivedStateFromProps(newProps, prevState) {
    return null
  }

  componentDidMount() {
    return null
  }

  componentDidUpdate(newProps, prevState) {
    return null
  }

  // A chaque focus d'un champ
  handleFocus(e) {
    this.setState({focused: e.target.name})
  }

  // A chaque sortie de focus d'un champ
  handleBlur(e) {
    this.setState({focused: ""})
  }

  // A chaque modification d'un champ
  handleChange(e) {
    // On met à jour le champ dans le tableau du state
    let fields = this.state.fields
    fields[e.target.name] = (e.target.type === 'checkbox' ? e.target.checked : e.target.value)
    this.setState({fields: fields})
    localStorage.setItem('Contact', JSON.stringify(fields))
  }

  // A la soumission du formulaire
  handleSubmit(e) {
    e.preventDefault()

    const {form, fields} = this.state

    // Vérification des erreurs sur les champs
    /*
    const isError = functions.checkErrors(this, etapes, 1, true)
    if ( !isError ) {
      const formDatas = Object.assign({form: form}, fields)
      functions.saveFormDatas(this, formDatas)
    }
    */

    const errors = {}
    etapes[0].fields.map( field => {
      if ( field.required && !fields[field.name] ) {
        errors[field.name] = field.listError
      }
    })

    // Mise à jour après vérification
    if ( Object.keys(errors).length ) {
      this.setState({
        status: "error",
        fieldErrors: errors,
        focused: Object.keys(errors)[0]
      })
    }
    else {
      this.setState({
        status: "",
        fieldErrors: {},
        returnMessage: "",
        isLoading: true
      })

      const formDatas = Object.assign({form: form}, fields)
      functions.saveFormDatas(this, formDatas)
    }
  }

  // A chaque sortie de focus d'un champ
  refreshForm(e) {
    this.setState({
      status: "",
      returnMessage: ""
    })
  }

  // Charge la popin
  loadPopin(e, page) {
    e.preventDefault()
    const content = this.state.popin.content
    if ( content ) { // Si popin déjà chargée
      this.setState({
        popin: {
          content: content,
          display: true
        }
      })
    }
    else { // Sinon, on charge le contenu en asynchrone
      functions.loadPopinContent(this, this.controller.signal, page)
    }
  }

  // Ferme la popin
  closePopin(e) {
    e.preventDefault()
    const content = this.state.popin.content
    this.setState({
      popin: {
        content: content,
        display: false
      }
    })
  }

  render() {
    console.log("CONTACTFORM RENDER")
    const {fields, focused, fieldErrors, status, popin, returnMessage, isLoading} = this.state

    return (
      <div id="" className={`form_bloc ${isLoading ? 'loadin' : ''}`}>

        <div className={`return_message ${(status !== "") && status}`}>
          {Object.keys(fieldErrors).length
            ?
            <>
              Veuillez compléter les champs suivants :
              <ul>
                {
                Object.values(fieldErrors).map((error, index) =>
                  <li key={`error-${index}`}>{error}</li>
                )}
              </ul>
            </>
            :
            returnMessage
          }
        </div>

        {(status === "succes")
          ?
          <button type="button" onClick={this.refreshForm}>Soumettre une nouvelle demande</button>
          :
          <form className="fullGrid" onSubmit={this.handleSubmit}>
            {// Rendu des champs par étape
             etapes[0].fields.map((field, index) =>
               <SwitchField key={field.name}
                  {...field}
                  fieldError={fieldErrors[field.name]}
                  value={fields[field.name]}
                  focused={(focused===field.name ? true : false)}
                  onFocus={this.handleFocus}
                  onBlur={this.handleBlur}
                  onChange={this.handleChange}
                  onClick={(e) => this.loadPopin(e, field.link)}
                  />
            )}
            <div className="mpSubmit grid100">
              <button type="submit">Envoyer votre message</button>
            </div>
          </form>
        }

        <PopinPage {...popin} onClick={(e) => this.closePopin(e)} />

      </div>
    )
  }
}


// PropTypes
ContactForm.propTypes = {
  state: PropTypes.arrayOf(
    PropTypes.shape({
      lat: PropTypes.number,
      lng: PropTypes.number
    })
  )
}

export default ContactForm
