import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import SwitchField from '../components/SwitchField'
import PopinPage from '../components/PopinPage'
import functions from '../functions/functions.js'
import account from '../functions/account.js'

// Tableau des champs par étape
const etapes = [
  // 1 seule étape sur le formulaire
  { step: 1,
    fields: [
      { name: "login",
        type: "email",
        classe: "",
        label: "Email",
        placeholder: "Saisissez votre email",
        listError: "Votre email",
        required: true,
      },
      { name: "pwd",
        type: "password",
        classe: "",
        label: "Mot de passe",
        placeholder: "Saisissez votre mot de passe",
        listError: "Votre mot de passe",
        required: true,
      }
    ]
  }
]


// Initialise les champs si localStorage existe
const formdatas = {
  login: "hello@nicoblandel.fr",
  pwd: "nbTheme$28",
}

export default function CompteLogin() {
  // constructor(props) {
  //   super(props);
  //   this.state = {
  //     fields: formdatas,
  //     focused: "", // Champ courant ayant le focus
  //     status: '', // Statut du formulaire : vide, succes ou error
  //     popin: {
  //       content: '', // Contenu de la popin
  //       display: false // Affichage de la popin
  //     },
  //     fieldErrors: {}, // Liste des champs en erreur
  //     returnMessage: '', // Message d'erreur
  //     isLoading: false
  //   }
  //
  //   // Controller du fetch
  //   this.controller = new window.AbortController()
  // }

  // A chaque focus d'un champ
  const handleFocus = (e) => {
    this.setState({focused: e.target.name})
  }

  // A chaque sortie de focus d'un champ
  const handleBlur = (e) =>  {
    this.setState({focused: ""})
  }

  // A chaque modification d'un champ
  const handleChange = (e) =>  {
    // On met à jour le champ dans le tableau du state
    let fields = this.state.fields
    fields[e.target.name] = (e.target.type === 'checkbox' ? e.target.checked : e.target.value)
    this.setState({fields: fields})
    // localStorage.setItem('Contact', JSON.stringify(this.state.fields))
  }

  // A la soumission du formulaire
  const handleSubmit = (e) =>  {
    e.preventDefault()

    const {fields} = this.state

    // Vérification des erreurs sur les champs
    const isError = functions.checkErrors(this, etapes, 1, true)
    if ( !isError ) {
      account.tryLogin(this, fields)
    }

    let errors = {}
    // etapes[0].fields.map( field => {
    //   if ( field.required && !fields[field.name] ) {
    //     errors[field.name] = field.listError
    //   }
    // })
    etapes[0].fields
      .filter( function(field) {
        return ( field.required && !fields[field.name] );
      })
      .map(function(field) {
        errors[field.name] = field.listError
      })

    // Mise à jour après vérification
    if ( Object.keys(errors).length ) {
      this.setState({
        status: "error",
        fieldErrors: errors,
        focused: Object.keys(errors)[0]
      })
    }
    else {
      this.setState({
        status: "",
        fieldErrors: {},
        returnMessage: "",
        isLoading: true
      })

      // const formDatas = Object.assign({form: form}, fields)
      // functions.saveFormDatas(this, formDatas)
    }
  }

  // A chaque sortie de focus d'un champ
  const refreshForm = (e) => {
    this.setState({
      status: "",
      returnMessage: ""
    })
  }

  // Charge la popin
  const loadPopin = (e, page) => {
    e.preventDefault()
    const content = this.state.popin.content
    if ( content ) { // Si popin déjà chargée
      this.setState({
        popin: {
          content: content,
          display: true
        }
      })
    }
    else { // Sinon, on charge le contenu en asynchrone
      functions.loadPopinContent(this, this.controller.signal, page)
    }
  }

  // Ferme la popin
  const closePopin = (e) => {
    e.preventDefault()
    const content = this.state.popin.content
    this.setState({
      popin: {
        content: content,
        display: false
      }
    })
  }

  const {fields, focused, fieldErrors, status, popin, returnMessage, isLoading} = this.state

  return (
    <div id="" className={`form_bloc ${isLoading ? 'loadin' : ''}`}>

      <div className={`return_message ${(status !== "") && status}`}>
        {Object.keys(fieldErrors).length
          ?
          <>
            Veuillez compléter les champs suivants :
            <ul>
              {
              Object.values(fieldErrors).map((error, index) =>
                <li key={`error-${index}`}>{error}</li>
              )}
            </ul>
          </>
          :
          returnMessage
        }
      </div>

      <form className="fullGrid" onSubmit={this.handleSubmit}>
        {// Rendu des champs par étape
         etapes[0].fields.map((field, index) =>
           <SwitchField key={field.name}
              {...field}
              fieldError={fieldErrors[field.name]}
              value={fields[field.name]}
              focused={(focused===field.name ? true : false)}
              onFocus={this.handleFocus}
              onBlur={this.handleBlur}
              onChange={this.handleChange}
              onClick={(e) => this.loadPopin(e, field.link)}
              />
        )}
        <div className="mpSubmit grid100">
          <button type="submit">Se connecter</button>
        </div>
      </form>

      <PopinPage {...popin} onClick={(e) => this.closePopin(e)} />

    </div>
  )
}
