// Librairies
import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import HTMLink from '../components/HTMLink'
// import PropTypes from 'prop-types'
import useLoadDatas from '../hooks/useLoadDatas'
import MapProducteur from '../components/MapProducteur'
import HelmetRender from '../layouts/HelmetRender'
import Breadcrumbs from '../layouts/Breadcrumbs'
import Content from '../components/Content'
import Icon from '../components/Icon'
import Carousel from '../components/Carousel'
import NotFound from '../components/NotFound'
import functions from '../functions/functions.js'
import parse from 'html-react-parser'

export default function ProdFiche() {

  // Paramètres d'url
  const { region, departement, slug } = useParams()

  const [datas, setDatas] = useState({
    jsonld: null,
    content: null,
    show_tel: false,
    show_mob: false
  })

  // Chargement des données
  const [isLoading, setIsLoading] = useState(true)
  const [isError, setIsError] = useState(false)

  const routeDatas = 'post?slug='+slug
  const [fetchDatas] = useLoadDatas()

  useEffect( () => {
    async function asyncFetch() {
      const data = await fetchDatas("producteur", {slug: slug})

      if ( data.error ) {
        setIsError(true)
      }
      else {
        setDatas({...datas,
                  jsonld: data.jsonld,
                  content: data.content
                })
      }

      setIsLoading(false)
    }
    asyncFetch()
  }, [routeDatas])

  // Gère l'affichage du téléphone
  const showTel = (e, type) =>  {
    switch ( type ) {
      case 'tel':
        setDatas({...datas, show_tel: (datas.show_tel ? false : true), show_mob: false})
      break;
      case 'mob':
        setDatas({...datas, show_mob: (datas.show_mob ? false : true), show_tel: false})
      break;
    }
  }

  // const { id, jsonld, error, datas, show_tel, show_mob, popin, currentTab, isLoading } = this.state
  // const { match } = this.props

  return (
    <main id="site-main">
      <ul className="todolist enter">
        <li>Visuel à la une ?</li>
        <li>Créer miniature photos + aperçu en full (charger lien thumb + grandes photos)</li>
        <li>Créer tabulations parties</li>
        <li>Problème Map : conflit avec double Popup</li>
        <li>Ajouter geoloc de l'internaute si active</li>
        <li>Ajouter producteurs à proximité ? des tags ?</li>
        <li>Revoir gestion des type-produit/précisions/produits</li>
      </ul>

      {!isLoading &&
        <>
          {isError // Si pas de résultats de la route
            ?
            <NotFound id={slug} />
            :
            <>
              <HelmetRender json={datas.jsonld} />
              <Breadcrumbs datas={datas.jsonld.breadcrumb} />

              <div className="headGrid innerWidth enter">
                {datas.content.logo &&
                  <img className="logo" src={datas.content.logo.url} width={datas.content.logo.w} height={datas.content.logo.h} alt={`Logo de ${datas.content.title}`} />
                }
                <div className="header">
                  <h1 className="enter">{datas.content.title}</h1>
                  <h2 className="enter">{datas.content.metier}</h2>
                  {datas.content.desc && parse(datas.content.desc)}
                  {datas.content.produits &&
                    <ul className="headCats">
                      {Object.keys(datas.content.produits).map((type, index) =>
                        <li key={`type-${index}`} title={datas.content.produits[type].nom}>
                          <Icon icon={`cat-${type}`} />
                        </li>
                      )}
                    </ul>
                  }
                </div>
                <div className="headBtns">
                  {datas.content.tel &&
                    <>
                      <button
                        type="button"
                        onClick={(e) => showTel(e, 'tel')}
                        title="Voir le téléphone">
                        <Icon icon="tel" />
                      </button>

                      {datas.show_tel &&
                        <a href={`tel:${datas.content.tel.replace(/\s/g,'')}`}>{datas.content.tel}</a>}
                    </>
                  }
                  {datas.content.mob &&
                    <>
                      <button
                        type="button"
                        onClick={(e) => showTel(e, 'mob')}
                        title="Voir le mobile">
                        <Icon icon="tel" />
                      </button>

                      {datas.show_mob &&
                        <a href={`tel:${datas.content.mob.replace(/\s/g,'')}`}>{datas.content.mob}</a>
                      }
                    </>
                  }
                  {datas.content.siteweb &&
                    <a href={datas.content.siteweb} target="_blank" rel="noreferrer" title="Voir le site">
                      <Icon icon="external" />
                    </a>
                  }
                </div>
              </div>

              <h2 className="enter"><span>Points de vente</span></h2>
              <MapProducteur {...datas.content.adresses} />

              <div className="ads-container enter">
                {/*
                <AdSense.Google
                  client='ca-pub-8766648172843727'
                  slot='9237706329'
                  format='fluid'
                  responsive='true'
                  layoutKey='-gu-3+1f-3d+2z'
                  className='adsbygoogle enter'
                  style={{ display: 'block' }}
                  adtest='on'
                  />
                */}
                <ins className="adsbygoogle"
                     data-adtest="on"
                     data-ad-format="fluid"
                     data-ad-layout-key="-gu-3+1f-3d+2z"
                     data-ad-client={process.env.GOOGLE_ADSENSE_ID}
                     data-ad-slot="9237706329"></ins>
                <script>(adsbygoogle = window.adsbygoogle || []).push({})</script>
              </div>

              <div className="entry-content content-style innerWidth enter">
                {datas.content && (
                  <>
                    {datas.content.content && parse(datas.content.content, {a: HTMLink})}
                    {datas.content.blocks && <Content blocks={datas.content.blocks} />}

                    {/*datas.dispos &&
                      <div><strong>Dispos :</strong><br />{parse(datas.dispos)}</div>
                    }

                    <div>
                      {datas.nom &&
                        <p><strong>Nom :</strong><br />{datas.nom} {datas.prenom}</p>
                      }
                    </div>

                    {datas.plus &&
                      <div>
                        <strong>Plus :</strong>
                        <ul>
                          {Object.values(datas.plus).map((plus, index) =>
                            <li key={`plus-${index}`} id={plus.slug}>
                              {plus.name}
                            </li>
                          )}
                        </ul>
                      </div>
                    */}

                    {/*datas.produits &&
                      <>
                        <h2><span>Nos produits</span></h2>
                        <Carousel id="slider-produits" items={datas.produits} />
                      </>
                    */}

                    {/*
                      <ul>
                        {Object.values(datas.produits).map((type, index) =>
                          <li key={`type-${index}`}>
                            <NavLink to={type.url}>{type.name}</NavLink>
                            {parse(type.info)}
                            {type.produits &&
                              <ul>
                                {type.produits.map((produit, index) =>
                                  <li key={`produit-${produit}`}>
                                    <NavLink to={produit.url}>{produit.name}</NavLink>
                                  </li>
                                )}
                              </ul>
                            }
                          </li>
                        )}
                      </ul>
                     */}

                    {datas.content.parties.length > 0 &&
                      <div className="parties">
                        <div className="partie">
                          {datas.content.parties.map((partie, index) =>
                            <div key={`part-${index}`}>
                              <h2><span>{partie.titre}</span></h2>
                              {parse(partie.prez, {a: HTMLink})}
                            </div>
                          )}
                        </div>
                      </div>
                    }

                    {datas.content.photos.length > 0 &&
                      <div className="bloc-produits-saison">
                        <h2><span>En images</span></h2>
                        <Carousel id="slider-photos" items={datas.content.photos} />

                        {/*
                        <div className="fullGrid">
                          {datas.photos.map((photo, index) =>
                            <div key={`photo-${index}`} className="grid33">
                              <button type="button" className="photo"
                                onClick={(e) => this.openPopin(e, index)}>
                                <img
                                  src={photo.sizes.thumbnail}
                                  width={photo.sizes['thumbnail-width']}
                                  height={photo.sizes['thumbnail-height']}
                                  alt=""
                                  />
                              </button>
                            </div>
                          )}
                        </div>
                        <PopinImages {...popin} onClick={(e) => this.closePopin(e)}>
                          {datas.photos.map((photo, index) =>
                            <img
                              key={`popinphoto-${index}`}
                              src={photo['sizes']['home860']}
                              width={photo['sizes']['home860-width']}
                              height={photo['sizes']['home860-height']}
                              alt=""
                              />
                          )}
                        </PopinImages>
                        */}
                      </div>
                    }
                  </>
                )}
              </div>
            </>
          }
        </>
      }
    </main>
  )
}

// PropTypes
// ProdFiche.propTypes = {
//   state: PropTypes.arrayOf(
//     PropTypes.shape({
//       id: PropTypes.number.isRequired,
//       prevId: PropTypes.number,
//       datas: PropTypes.object.isRequired,
//       isLoading: PropTypes.bool.isRequired
//     }).isRequired
//   )
// }
//
// export default ProdFiche
