const geolocation = {

  getCurrentPosition: function() {
    console.log("getCurrentPosition()")
    if (navigator.geolocation) {
      return new Promise((resolve, reject) =>
        navigator.geolocation.getCurrentPosition(resolve, reject)
      )
    } else {
      return new Promise(
        resolve => resolve({})
      )
    }
  },

  refreshPosition: function(position) {
    // Traitement à la récupération des coordonnées du navigateur
    console.log("refreshPosition()")
  },

  stopPosition: function(error) {
    console.log("stopPosition()")
    // Traitement à si erreur lors de la récupération des coordonnées du navigateur
    switch(error.code){
      case error.PERMISSION_DENIED:
        console.warn("L'utilisateur n'a pas autorisé l'accès à sa position");
      break;
      case error.POSITION_UNAVAILABLE:
        console.warn("L'emplacement de l'utilisateur n'a pas pu être déterminé");
      break;
      case error.TIMEOUT:
        console.warn("Le service n'a pas répondu à temps");
      break;
      default:
    }
  }
}

export default geolocation
