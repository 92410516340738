import React, { Fragment } from 'react'
import { NavLink } from 'react-router-dom'

class NotFound extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      id: null,
      prevId: null,
    }
  }

  /* Compare le nouvel état du composant avec son ancien état */
  static getDerivedStateFromProps(newProps, prevState) {
    if ( newProps.id !== prevState.prevId ) {
      console.log('404 SHOULD UPDATE!', newProps.id, prevState.prevId)
      return {
        id: newProps.id,
        prevId: prevState.id
      }
    }
    return null
  }


  componentDidMount() {
    // Supprime la classe de chargement
    document.body.classList.remove("loadin")
  }


  render() {
    return (
      <div className="error404">
        <ul className="todolist">
          <li>Maquetter la 404</li>
          <li>Ajouter un breadcrumb Accueil > Page 404</li>
        </ul>
        <h1 className='enter'>{`Erreur...`}</h1>
        <div className='entry-content innerWidth enter'>
          <p>{`La contenu ne peut s'afficher correctement, peut-être un problème de réseau.`}</p>
          <NavLink to="/" className="btn">{`Retourner à l'accueil`}</NavLink>
        </div>
      </div>
    )
  }
}
export default NotFound
