// Librairies React
import React, { Component } from 'react'
import HTMLink from '../components/HTMLink'
import PropTypes from 'prop-types'
import parse from 'html-react-parser'

// Composant
class PopinPage extends Component {

  constructor(props) {
    super(props);
    this.state = {
      active: false
    }
  }

  // render du composant
  render() {
    const { content, display, onClick } = this.props

    return (
      <div className={`popin popin-rgpd ${display ? 'active' : ''}`}>
        <div className='popin-container'>
          <div className='popin-inner'>
            <div className='popin-content'>
              {content && parse(content, {a: HTMLink})}
            </div>
          </div>
          <div className='popin-bottom'>
            <button type='button' onClick={(e) => onClick(e)}>Merci !</button>
          </div>
        </div>
        <div className='popin-overlay'></div>
      </div>
    )
  }
}

export default PopinPage
