import React, { useState, useEffect, useRef } from 'react'
import { NavLink } from 'react-router-dom'
import Slider from 'react-slick'
import PopinPhotos from './PopinPhotos'
import parse from 'html-react-parser'

export default function Carousel(props) {
  let sliderRef = useRef(null)
  const [popin, setPopin] = useState({
    index: 0, // Index de la photo à afficher
    display: false // Affichage de la popin
  })

  // https://github.com/akiran/react-slick
  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    onInit: () => {
      //this.refreshSlider()
    },
    onReInit: () => {
      refreshSlider()
    },
    responsive: [
      { breakpoint: 1024,
        settings: { slidesToShow: 2, slidesToScroll: 2 }
      },
      { breakpoint: 600,
        settings: { slidesToShow: 2, slidesToScroll: 2 }
      },
      { breakpoint: 480,
        settings: { slidesToShow: 1, slidesToScroll: 1 }
      }
    ]
  }

  useEffect(() => {
    refreshSlider()

    // S'il y a des puces
    // if ( this.slider.props.dots ) {
    //   document.getElementById(this.props.id).classList.add('slick-dotted')
    // }
  }, [props])


  // Rafraichi le slider si
  // A voir pour mettre au resize uniquement
  // state pour le slidesToShow ?
  const refreshSlider = () => {
    if ( sliderRef.current ) {
      console.log(sliderRef.current)
      let slidetoshow = sliderRef.current.props.slidesToShow
      sliderRef.current.props.responsive.map((point, index) => {
        if ( sliderRef.current.state.breakpoint === point.breakpoint ) {
          slidetoshow = point.settings.slidesToShow
        }
      })

      if ( props.items.length < slidetoshow+1 ) {
        document.getElementById(props.id).classList.add('slick-unloaded')
      }
      else {
        document.getElementById(props.id).classList.remove('slick-unloaded')
      }
    }
  }

  const gotoPrev = (e) => {
    sliderRef.current.slickPrev()
  }

  const gotoNext = (e) => {
    sliderRef.current.slickNext()
  }

  // Ouvre la popin
  const openPopin = (e, index) => {
    e.preventDefault()
    setPopin({
      index: index,
      display: true
    })
    document.body.classList.add("disabled")
  }

  // Ferme la popin
  const closePopin = (e) => {
    e.preventDefault()
    setPopin({
      ...popin,
      display: false
    })
    document.body.classList.remove("disabled")
  }

  return (
    <>
      {props.items &&
        <div id={props.id} className="slider">
          <Slider ref={sliderRef} {...settings}>
            {props.items.map( (item, index) =>
              <div key={index}>
                {item.url ?
                  <NavLink to={item.url ? item.url : ''} className='item'>
                    {item.img &&
                      parse(item.img)
                    }
                    {item.nom &&
                      <span>{item.nom && parse(item.nom)}</span>
                    }
                  </NavLink>
                  :
                  <button type="button" className="photo"
                          onClick={(e) => openPopin(e, index)}>
                    <img
                      src={item.img}
                      width={item.sizes['thumbnail-width']}
                      height={item.sizes['thumbnail-height']}
                      alt=""
                      />
                  </button>
                }
              </div>
            )}
          </Slider>

          {popin.display &&
            <PopinPhotos {...popin} onClick={(e) => closePopin(e)}>
              {props.items.map((photo, index) =>
                <img
                  key={`popinphoto-${index}`}
                  src={photo.sizes['home860']}
                  width={photo.sizes['home860-width']}
                  height={photo.sizes['home860-height']}
                  alt=""
                  />
              )}
            </PopinPhotos>
          }

          <button className="arrow-prev" onClick={(e) => gotoPrev(e)}>
            <svg className='icon'>
              <use xlinkHref='/images/icons.svg#fleche'></use>
            </svg>
          </button>

          <button className="arrow-next" onClick={(e) => gotoNext(e)}>
            <svg className='icon'>
              <use xlinkHref='/images/icons.svg#fleche'></use>
            </svg>
          </button>
        </div>
      }
    </>
  )
}
