// Librairies React
import React from 'react'
import { NavLink } from 'react-router-dom'
import PropTypes from 'prop-types'
import functions from '../functions/functions.js'

// Composant
export default function HTMLink(props) {

  console.log("HTMLink", props)

  // Si href appartient au domain du site
  if ( props.href
       && (props.href.indexOf(process.env.REACT_APP_URI) >= 0
           ||props.href.indexOf(process.env.REACT_APP_WP) >= 0) ) {
    return (
      <NavLink to={functions.cleanURL(props.href)} className={props.className}>
        {props.children}
      </NavLink>
    )
  }

  // Sinon, par défaut, href vers site externe
  return <a {...props} target="_blank" rel="noreferrer">{props.children[0]}</a>
}
