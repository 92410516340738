// Librairies React
import React, { Component } from 'react'

// Sprite SVG
// import sprite from '/images/icons.svg';

// Composant
class Icon extends Component {
  // render du composant
  render() {
    return (
      <svg className={`icon${this.props.class ? ' '+this.props.class : ''}`}>
        {this.props.title ? <text>{this.props.title}</text> : ''}
        <use xlinkHref={`/images/icons.svg#${this.props.icon}`}></use>
      </svg>
    )
  }
}

export default Icon
