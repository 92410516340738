// Librairies
import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import HTMLink from '../components/HTMLink'
// import PropTypes from 'prop-types'
import useLoadDatas from '../hooks/useLoadDatas'
import CompteLogin from '../components/CompteLogin'
import Dashboard from '../components/Dashboard'
import HelmetRender from '../layouts/HelmetRender'
import Breadcrumbs from '../layouts/Breadcrumbs'
import Content from '../components/Content'
import NotFound from '../components/NotFound'
import functions from '../functions/functions.js'
import parse from 'html-react-parser'

// Composant
export default function Compte() {
  const slug = 'compte'

  const [datas, setDatas] = useState(null)
  const [isLogged, setIsLogged] = useState(false)

  // Chargement des données
  const [isLoading, setIsLoading] = useState(true)
  const [isError, setIsError] = useState(false)

  const routeDatas = 'post?slug='+slug
  const [fetchDatas] = useLoadDatas()

  useEffect( () => {
    async function asyncFetch() {
      const data = await fetchDatas("page", {slug: slug})

      if ( data.error ) {
        setIsError(true)
      }
      else {
        setDatas({
          jsonld: data.jsonld,
          content: data.content
        })
      }

      setIsLoading(false)
    }
    asyncFetch()
  }, [routeDatas])

  return (
    <main id="site-main">
      <Breadcrumbs datas={datas && datas.jsonld && datas.jsonld.breadcrumb} />

      {!isLoading &&
        <>
          {isError // Si pas de résultats de la route
            ?
            <NotFound id={slug} />
            :
            <>
              {!isLogged // Si utilisateur pas logué
                ?
                <>
                  <HelmetRender json={datas.jsonld} />
                  <h1 className='enter'>
                    {datas.title && parse(datas.content.title)}
                  </h1>
                  <div className='entry-content enter'>
                    {datas.content.content && parse(datas.content.content, {a: HTMLink})}
                    {datas.content.blocks && <Content blocks={datas.content.blocks} />}
                  </div>
                  <div className='enter'>
                    <CompteLogin />
                  </div>
                </>
                :
                <>
                  <HelmetRender json={datas.jsonld} />
                  <h1 className='enter'>
                    {datas.content.title && parse(datas.content.title)}
                  </h1>
                  <div className='entry-content enter'>
                    {datas.content.content && parse(datas.content.content, {a: HTMLink})}
                    {datas.content.blocks && <Content blocks={datas.content.blocks} />}
                  </div>
                  <div className='enter'>
                    <Dashboard />
                  </div>
                </>
              }
            </>
          }
        </>
      }
    </main>
  )
}
