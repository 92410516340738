import React, { useState } from 'react'
import PropTypes from 'prop-types'
import SwitchField from '../components/SwitchField'
import PopinPage from '../components/PopinPage'
import functions from '../functions/functions.js'

// Définitions des champs
const objets = [
  "Demande simple",
  "Remerciement",
  "Correction d'informations sur le site"
]

// Tableau des champs par étape
const etapes = [
  // Étape 1
  { step: "Étape 1 : noms",
    fields: [
      { name: "nom",
        type: "text",
        class: "",
        label: "Nom",
        placeholder: "Saisissez votre nom",
        listError: "Votre nom",
        required: true,
      },
      { name: "prenom",
        type: "text",
        class: "",
        label: "Prénom",
        placeholder: "Saisissez votre prénom",
        listError: "Votre prénom",
        required: true,
      }
    ]
  },
  // Étape 2
  { step: "Étape 2 : email",
    fields: [
      { name: "email",
        type: "email",
        class: "",
        label: "Email",
        placeholder: "Saisissez votre email",
        listError: "Votre email",
        required: true,
      }
    ]
  },
  // Étape 3
  { step: "Étape 3 : confirmation",
    fields: [
      { name: "objet",
        type: "select",
        class: "grid100",
        label: "Objet",
        placeholder: "Précisez l'objet de votre contact",
        listError: "L'objet de votre demande",
        required: true,
        options: objets
      },
      { name: "message",
        type: "textarea",
        class: "grid100",
        label: "Message",
        placeholder: "Saisissez votre message",
        listError: "Votre message",
        required: true
      },
      { name: "rgpd",
        class: "grid100",
        link: "page-d-exemple",
        listError: "L'acceptation de notre politique de confidentialité",
        required: true
      }
    ]
  }
]


// Initialise les champs si localStorage existe
const formdatas = (
  localStorage.getItem('Inscription')
  ?
  JSON.parse(localStorage.getItem('Inscription'))
  :
  {
    nom: "",
    prenom: "",
    email: "",
    objet: "",
    message: "",
    choix: [],
    rgpd: false
  }
)


export default function InscriProducteurForm() {

  const [state, setState] = useState({
    form: "InscriProducteur",
    fields: formdatas,
    step: 1, // étape du formulaire
    focused: "", // Champ courant ayant le focus
    status: '', // Statut du formulaire : vide, succes ou error
    popin: {
      content: '', // Contenu de la popin
      display: false // Affichage de la popin
    },
    fieldErrors: {}, // Liste des champs en erreur
    returnMessage: '', // Message d'erreur
    isLoading: false
  })

  // Controller du fetch
  // this.controller = new window.AbortController()

  // A chaque focus d'un champ
  const handleFocus = (e) => {
    setState({
      ...state,
      focused: e.target.name
    })
  }

  // A chaque sortie de focus d'un champ
  const handleBlur = (e) => {
    setState({
      ...state,
      focused: ""
    })
  }

  // A chaque modification d'un champ
  const handleChange = (e) => {
    // On met à jour le champ dans le tableau du state
    let fields = state.fields
    fields[e.target.name] = (e.target.type === 'checkbox' ? e.target.checked : e.target.value)
    setState({
      ...state,
      fields: fields
    })
    localStorage.setItem('Inscription', JSON.stringify(fields))
  }

  // Retour à l'étape précédente
  const prevStep = (e) => {
    setState({
      ...state,
      step: (state.step-1),
      status: "",
      focused: etapes[state.step-2].fields[0].name, // Focus sur le 1er élément de l'étape suivante
      fieldErrors: {},
      returnMessage: ""
    })
  }

  // Continue vers l'étape suivante
  const nextStep = (e) => {
    // Vérification des erreurs sur les champs
    const errors = {}
    etapes[state.step-1].fields.map( field => {
      if ( field.required && !state.fields[field.name] ) {
        errors[field.name] = field.listError
      }
    })

    // Mise à jour après vérification
    if ( Object.keys(errors).length ) { // Si errors
      setState({
        ...state,
        status: "error",
        fieldErrors: errors,
        focused: Object.keys(errors)[0]
      })
    }
    else {
      setState({
        ...state,
        step: (state.step+1),
        status: "",
        focused: etapes[state.step].fields[0].name, // Focus sur le 1er élément de l'étape suivante
        fieldErrors: {},
        returnMessage: ""
      })
    }
  }

  // Continue vers l'étape suivante
  const gotoStep = (e, etape) => {
    e.preventDefault()

    setState({
      ...state,
      step: etape,
      status: "",
      focused: etapes[etape-1].fields[0].name, // Focus sur le 1er élément de l'étape suivante
      fieldErrors: {},
      returnMessage: ""
    })
  }

  // A la soumission du formulaire
  const handleSubmit = (e) => {
    e.preventDefault()

    console.log("submit", state.step)

    // Vérification des erreurs sur les champs
    const errors = {}
    etapes[state.step-1].fields.map( field => {
      if ( field.required && !state.fields[field.name] ) {
        errors[field.name] = field.listError
      }
    })

    // Mise à jour après vérification
    if ( Object.keys(errors).length ) {
      setState({
        ...state,
        status: "error",
        fieldErrors: errors
      })
    }
    else {
      setState({
        ...state,
        status: "",
        fieldErrors: {},
        returnMessage: "",
        isLoading: true
      })

      console.log("submitting...")

      //const formDatas = Object.assign({form: form}, fields)
      //functions.saveFormDatas(this, formDatas)
    }
  }

  // Charge la popin
  const loadPopin = (e, page) => {
    e.preventDefault()
    const content = state.popin.content
    if ( content ) { // Si popin déjà chargée
      setState({
        ...state,
        popin: {
          content: content,
          display: true
        }
      })
    }
    else { // Sinon, on charge le contenu en asynchrone
      functions.loadPopinContent(this, this.controller.signal, page)
    }
  }

  // Ferme la popin
  const closePopin = (e) => {
    e.preventDefault()
    const content = state.popin.content
    setState({
      ...state,
      popin: {
        content: content,
        display: false
      }
    })
  }

  return (
    <div className={`form_bloc ${state.isLoading && "loadin"}`}>
      <ul id="steps">
        { etapes.map((etape, index) =>
          <li key={`step ${index}`} className={(state.step===index+1) ? 'current' : ''}>
            {(state.step > index+1)
              ?
              <button type='button' onClick={(e) => gotoStep(e, index+1)}>{etape.step}</button>
              :
              <span>{etape.step}</span>
            }
          </li>
        )}
      </ul>

      <div className={`return_message ${(state.status !== "") && state.status}`}>
        {Object.keys(state.fieldErrors).length
          ?
          <>
            Veuillez compléter les champs suivants :
            <ul>
              {
              Object.values(state.fieldErrors).map((error, index) =>
                <li key={`error-${index}`}>{error}</li>
              )}
            </ul>
          </>
          :
          state.returnMessage
        }
      </div>

      <form className="fullGrid" onSubmit={handleSubmit}>

        <>
          {// Rendu des champs par étape
           etapes[state.step-1].fields.map((field, index) =>
             <SwitchField key={field.name}
                {...field}
                fieldError={state.fieldErrors[field.name]}
                value={state.fields[field.name]}
                focused={(state.focused===field.name ? true : false)}
                onFocus={handleFocus}
                onBlur={handleBlur}
                onChange={handleChange}
                onClick={(e) => loadPopin(e, field.link)}
                />
          )}
        </>

        <div className="mpSubmit grid100">
          {(state.step>1)
            &&
            <button type="button" onClick={prevStep}>Retour</button>
          }
          {(state.step<3)
            ?
            <button type="button" onClick={nextStep}>Continuer</button>
            :
            <button type="button"onClick={handleSubmit}>Envoyer votre message</button>
          }
        </div>

        <PopinPage {...state.popin} onClick={(e) => closePopin(e)} />

      </form>
    </div>
  )
}
