// Librairies
import React, { Fragment, Component } from 'react'
import HTMLink from '../components/HTMLink'
import parse from 'html-react-parser'

// Composant
class Don extends Component {

  constructor(props) {
    super(props);
    this.state = {
      value: 0
    }
  }

  // Compare le nouvel état du composant avec son ancien état
  static getDerivedStateFromProps(newProps, prevState) {
    return null
  }

  // Au chargement du composant
  async componentDidMount() {
    document.body.classList.remove("loadin")
  }

  // A l'update du composant
  async componentDidUpdate(newProps, prevState) {
  }

  // A la suppression du composant
  componentWillUnmount() {
    console.log('PAGE WILL UNMOUNT')
  }

  changeEvas(e) {
    const canvas = document.getElementById('smiley');
    const context = canvas.getContext('2d');

    const value = e.target.value

    this.setState({value: value})

    // face
    context.beginPath();
    context.arc(100, 100, 75, 0, 2 * Math.PI);
    const gradient = context.createRadialGradient(100, 100, 50, 75, 75, 100);
    gradient.addColorStop(0, "yellow");
    gradient.addColorStop(1, "orange");
    context.fillStyle = gradient;
    context.fill();

    // left eye
    context.beginPath();
    context.arc(100 - 25, 75, 7, 0, 2 * Math.PI);
    context.fillStyle = 'black';
    context.fill();

    // right eye
    context.beginPath();
    context.arc(100 + 25, 75, 7, 0, 2 * Math.PI);
    context.fillStyle = 'black';
    context.fill();

    // mouth
    context.beginPath();
    context.moveTo(60, 125);
    // 0 => 90 / 100 => 160
    context.quadraticCurveTo(100, parseInt(value/10*7) + 90, 140, 125);
    context.lineCap = "round";
    context.strokeStyle = 'black';
    context.lineWidth = 4;
    context.stroke();
  }

  render() {

    return (
      <main id="site-main">
        <ul className="todolist">
          <li>Tester Don</li>
          <li>Lier à plateforme de paiement ?</li>
        </ul>
        <div>
          <canvas id="smiley" width="200" height="200"></canvas>
          <form action="#" method="post">
            <label htmlFor="evas">Faites un don</label>
            <input
              name="evas"
              id="evas"
              type="range"
              min="0" max="100" step="0.1" value={this.state.value}
              onChange={(e) => this.changeEvas(e)}
              onKeyPress={(e) => this.changeEvas(e)}
              onMouseMove={(e) => this.changeEvas(e)} />
            <br />
            <input id="submit" name="submit" type="submit" value="Ok" />
          </form>
        </div>
      </main>
    )
  }
}

export default Don
