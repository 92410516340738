import React, { useState, useEffect } from 'react'
import { Route, Routes, useLocation } from 'react-router-dom'
import { TransitionGroup, CSSTransition } from "react-transition-group"
import { Helmet, HelmetProvider } from 'react-helmet-async'
import PropTypes from 'prop-types'

// https://dev.to/fazliddin04/react-router-v6-animated-transitions-diy-3e6l

// Firebase
import {requestToken, listenPermission, onMessageListener} from './firebase/firebase.js'

import Header from './layouts/Header'
import Footer from './layouts/Footer'
import Loader from './components/Loader'
import Home from './templates/Home'
import Page from './templates/Page'
import Compte from './templates/Compte'
import Don from './templates/Don'
import Contact from './templates/Contact'
import InscriProducteur from './templates/InscriProducteur'
import Produit from './templates/Produit'
import Producteur from './templates/Producteur'
import ListProducteurs from './templates/ListProducteurs'
import ListProduits from './templates/ListProduits'
import Recherche from './templates/Recherche'
import PageNotFound from './templates/PageNotFound'
import geolocation from './functions/geolocation.js'
import notifications from './functions/notifications.js'

// Initialise les champs si localStorage existe
/*
localStorage.setItem('contactform', data)
localStorage.getItem('contactform')
localStorage.removeItem('contactform')
localStorage.clear()
*/

export default function MonProd() {
  const location = useLocation()
  // const [displayLocation, setDisplayLocation] = useState(location)
  // const [transitionStage, setTransistionStage] = useState("fadeIn")

  const [position, setPosition] = useState(
    localStorage.getItem('position')
    ?
    JSON.parse(localStorage.getItem('position'))
    :
    {
      lat: null,
      lng: null
    }
  )
  const [data, setData] = useState({
    id: null,
    type: '',
    position: position,
    isHome: true
  })

  // useEffect(() => {
  //   if ( location !== displayLocation ) {
  //     setTransistionStage("fadeOut")
  //   }
  // }, [location, displayLocation])

  useEffect(() => {
    // Géolocalisation
    geolocation.getCurrentPosition()
      .then(position => {
        console.log("MONPROD getCurrentPosition")

        // Change si position différente de celle stockée dans le localStorage
        if ( position.lat !== data.position.coords.latitude
             && position.lng !== data.position.coords.longitude ) {
          const current_position = {lat: data.position.coords.latitude, lng: data.position.coords.longitude}
          this.setState({position: current_position})
          setPosition(current_position)
          localStorage.setItem('position', JSON.stringify(current_position))
        }
      })
      .catch(error => {
        console.log(error.message)
        // geolocation.stopPosition(error)
      })
  }, [location])

  return (
    <>
      <HelmetProvider>
        <Header {...data.position} />
        <Routes location={location}>
          <Route path="/" element={<Home />} />
          <Route path="/don/" element={<Don />} />

          <Route path={"/france/:region/:departement/:slug/"} element={<Producteur />} />
          <Route path={"/france/:region/:departement/"} element={<ListProducteurs />} />
          <Route path={"/france/:region/"} element={<ListProducteurs />} />
          <Route path={"/recherche/"} element={<Recherche />} />

          <Route path={"/produits/:type/:produit/"} element={<Produit />} />
          <Route path={"/produits/:type/"} element={<ListProduits />} />

          <Route path={"/compte/"} element={<Compte />} />
          <Route path={"/contact/"} element={<Contact />} />
          <Route path={"/inscription-producteur/page/:step/"} element={<InscriProducteur />} />
          <Route path={"/inscription-producteur/"} element={<InscriProducteur />} />

          <Route path={"/:slug1/:slug2/"} element={<Page />} />
          <Route path={"/:slug1/"} element={<Page />} />

          <Route element={<PageNotFound />} />
        </Routes>
        <Loader id="mainLoader" />
        <Footer />
      </HelmetProvider>

      {/*
      <Router basename={`${process.env.REACT_APP_BASE}`}>
        <Route onChange={bodyChange} render={({ location }) => (
          <HelmetProvider>
            <Header {...data.position} home={location.pathname==="/" ? true : false} />
            <TransitionGroup>
              <CSSTransition
                key={location.key}
                classNames="fade"
                timeout={600}
                onEntering = {onEnteringHandler}
                >
                <Routes location={location}>
                  <Route exact path="/" component={Home} />
                  <Route exact path="/don/" component={Don} />

                  <Route exact path={"/france/:region/:departement/:slug/"} component={Producteur} />
                  <Route exact path={"/france/:region/:departement/"} component={ListProducteurs} />
                  <Route exact path={"/france/:region/"} component={ListProducteurs} />
                  <Route exact path={"/recherche/"} component={Recherche} />

                  <Route exact path={"/produits/:type/:produit/"} component={Produit} />
                  <Route exact path={"/produits/:type/"} component={ListProduits} />

                  <Route exact path={"/compte/"} component={Compte} />
                  <Route exact path={"/contact/"} component={Contact} />
                  <Route exact path={"/inscription-producteur/page/:step/"} component={InscriProducteur} />
                  <Route exact path={"/inscription-producteur/"} component={InscriProducteur} />

                  <Route exact path={"/:slug1/:slug2/"} component={Page} />
                  <Route exact path={"/:slug1/"} component={Page} />

                  <Route component={PageNotFound} />
                </Routes>
              </CSSTransition>
            </TransitionGroup>
            <Loader id="mainLoader" />
            <Footer />
          </HelmetProvider>
        )} />
      </Router>
      */}
    </>
  )
}

// PropTypes
// MonProd.propTypes = {
//   state: PropTypes.arrayOf(
//     PropTypes.shape({
//       items: PropTypes.array.isRequired,
//       menuLoading: PropTypes.bool.isRequired,
//       error: PropTypes.object
//     }).isRequired
//   )
// }
