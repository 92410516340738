import { initializeApp } from 'firebase/app'
import { getMessaging, getToken, onMessage } from 'firebase/messaging'

const publicKey = process.env.REACT_APP_VAPID_KEY
const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID
}
const firebaseApp = initializeApp(firebaseConfig)
const messaging = getMessaging(firebaseApp)

export const requestToken = (setTokenFound) => {
  return getToken(messaging, {vapidKey: process.env.REACT_APP_VAPID_KEY})
    .then((currentToken) => {
      if (currentToken) {
        // console.log('Firebase token:', currentToken)
        setTokenFound(currentToken)
      } else {
        console.log('No registration token available. Request permission to generate one.')
        setTokenFound(null)
      }
    }).catch((err) => {
      console.log('An error occurred while retrieving token. ', err)
      // catch error while creating client token
    });
}

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload)
    })
})

export const listenPermission = (tokenFound) => {
  navigator.permissions.query({name: 'notifications'}).then(function(permission) {
    // Initial status is available at permission.state
    console.log("Notification permission init", permission.state)
    console.log(tokenFound)

    permission.onchange = function() {
      // Whenever there's a change, updated status is available at this.state
      console.log("Notification permission change", permission.state)
      console.log(tokenFound)
    }
  })
}
