// Librairies
import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import HTMLink from '../components/HTMLink'
// import PropTypes from 'prop-types'
import useLoadDatas from '../hooks/useLoadDatas'
import Loader from '../components/Loader'
import Map from '../components/Map'
import MapFormRecherche from '../components/MapFormRecherche'
import ProdBloc from '../components/ProdBloc'
import HelmetRender from '../layouts/HelmetRender'
import Breadcrumbs from '../layouts/Breadcrumbs'
import NotFound from '../components/NotFound'
import functions from '../functions/functions.js'
import parse from 'html-react-parser'

// Composant
export default function Recherche() {

  // Paramètres d'url
  const slug = 'recherche'
  const filtres = JSON.parse(localStorage.getItem('filtres'))
  const location = ((filtres && filtres.location) ? filtres.location : JSON.parse(localStorage.getItem('position')))

  const [datas, setDatas] = useState(null)

  // Chargement des données
  const [isLoading, setIsLoading] = useState(true)
  const [isError, setIsError] = useState(false)

  const routeDatas = 'post?slug='+slug
  const [fetchDatas] = useLoadDatas()

  useEffect( () => {
    asyncFetch()
  }, [routeDatas])

  async function asyncFetch() {
    console.log(filtres)
    const data = await fetchDatas("recherche", filtres)

    if ( data.error ) {
      setIsError(true)
    }
    else {
      console.log("resultats recherche", data)
      setDatas({
        jsonld: data.jsonld,
        content: data.content
      })
    }

    setIsLoading(false)
  }

  const handleSubmit = async (filtres) => {
    setIsLoading(true)

    document.body.classList.add("loadin")
    document.body.classList.remove("searchin")
    document.getElementById("site-main").removeAttribute("style")

    console.log('handleSubmit', filtres)

    const data = await fetchDatas("recherche", filtres)

    if ( data.error ) {
      setIsError(true)
    }
    else {
      console.log("resultats recherche", data)
      setDatas({
        ...datas,
        content: data.content
      })
    }

    setIsLoading(false)
  }


  return (
    <main id="site-main">
      <ul className="todolist enter">
        <li>{`Page uniquement d'atterrissage`}</li>
        <li>Ajouter des google adsenses</li>
        <li>Chargement infini au clic</li>
      </ul>

      {!isLoading &&
        <>
          {isError // Si pas de résultats de la route
            ?
            <NotFound id={slug} />
            :
            <>
              <HelmetRender json={datas.jsonld} />

              <MapFormRecherche selectedProduits='' newSubmit={handleSubmit} />

              <Breadcrumbs datas={datas.jsonld.breadcrumb} />
              {/*
                <h1 className='enter'>
                  {datas.title && parse(datas.title, {a: HTMLink})}
                </h1>
              */}
              <div className={`entry-content entry-recherche enter`}>
                {datas.content.prods
                  ?
                  <>
                    {datas.content.prods &&
                      <Map lat={location.lat} lng={location.lng} prods={datas.content.prods} />
                    }

                    {/* datas.prods.map( (prod) =>
                      <div key={`prod-${prod.id}`} className={`list-prod ${prod.type}`}>
                        <h3>{prod.title}</h3>
                        <p>{prod.metier}</p>
                        <p>{prod.intro}</p>
                        <p>{prod.cp} {prod.ville}</p>
                        <NavLink to={prod.url}>Découvrir le producteur</NavLink>
                      </div>
                    ) */}
                  </>
                  :
                  <>
                    <h1 className='enter'>
                      {datas.content.title && parse(datas.content.title, {a: HTMLink})}
                    </h1>
                    <p>Pas de producteurs</p>
                  </>
                }
              </div>
            </>
          }
        </>
      }
    </main>
  )
}
