// Librairies
import React, { Fragment, Component } from 'react'
import HTMLink from '../components/HTMLink'
import PropTypes from 'prop-types'
import ContactForm from '../components/ContactForm'
import HelmetRender from '../layouts/HelmetRender'
import Breadcrumbs from '../layouts/Breadcrumbs'
import Content from '../components/Content'
import NotFound from '../components/NotFound'
import functions from '../functions/functions.js'
import parse from 'html-react-parser'

// Composant
class Contact extends Component {

  constructor(props) {
    super(props);
    this.state = {
      id: null,
      prevId: null,
      jsonld: '',
      datas: null,
      error: false,
      isLoading: true
    }

    // Controller du fetch
    this.controller = new window.AbortController()
  }

  // Compare le nouvel état du composant avec son ancien état
  static getDerivedStateFromProps(newProps, prevState) {
    const slug = "contact"

    // Si id différent et pas en erreur
    if ( slug !== prevState.prevId ) {
      console.log('CONTACT SHOULD UPDATE!', slug, prevState.prevId)
      return {
        id: slug,
        prevId: prevState.id
      }
    }
    return null
  }

  // Au chargement du composant
  async componentDidMount() {
    await functions.loadAsyncDatas(this, this.controller.signal, "page", {slug: this.state.id})
  }

  // A l'update du composant
  async componentDidUpdate(newProps, prevState) {
    // Si id différent du prevId, update du composant
    if ( this.state.id !== this.state.prevId ) {
      console.log('CONTACT DID UPDATE')

      this._asyncRequest && this.controller.abort()

      // Met en place l'état de chargement
      this.setState({error: false, isLoading:true})

      await functions.loadAsyncDatas(this, this.controller.signal, "page", {slug: this.state.id})
    }
  }

  // A la suppression du composant
  componentWillUnmount() {
    console.log('CONTACT WILL UNMOUNT')
    this._asyncRequest && this.controller.abort()
  }

  render() {
    console.log("CONTACT RENDER")
    const { id, jsonld, datas, error, isLoading } = this.state

    return (
      <main id="site-main">
        <ul className="todolist enter">
          <li>Ajouter contenu Qui sommes-nous</li>
          <li>Traiter la sauvegarde du formulaire à soumission</li>
        </ul>
        <Breadcrumbs datas={jsonld && jsonld.breadcrumb} />

        {!isLoading &&
          <>
            {(error || !jsonld) // Si pas de résultats de la route
              ?
              <NotFound id={id} />
              :
              <>
                <HelmetRender json={jsonld} />
                <h1 className='enter'>
                  {datas.title && parse(datas.title)}
                </h1>
                <div className='entry-content enter'>
                  {datas.content && parse(datas.content, {a: HTMLink})}
                  {datas.blocks && <Content blocks={datas.blocks} />}
                </div>
                <div className='enter'>
                  <ContactForm />
                </div>
              </>
            }
          </>
        }
      </main>
    )
  }
}

// PropTypes
Contact.propTypes = {
  state: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      prevId: PropTypes.number,
      datas: PropTypes.object.isRequired,
      isLoading: PropTypes.bool.isRequired
    }).isRequired
  )
}

export default Contact
