import React from 'react'
import { createRoot, hydrateRoot } from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
// import Helmet, { HelmetProvider } from 'react-helmet-async';
// import './index.css';
// import './assets/css/MonProd.css';
import './assets/css/mp-styles.css';
import MonProd from './MonProd';
import reportWebVitals from './reportWebVitals';

import * as serviceWorker from './serviceWorker';

const container = document.getElementById("container")

if (container.hasChildNodes()) {
  const root = hydrateRoot(container, <BrowserRouter><MonProd /></BrowserRouter>)
} else {
  const root = createRoot(container)
  root.render(<BrowserRouter><MonProd /></BrowserRouter>)
}

/*
const app = (
  <HelmetProvider>
    <MonProd>
      <Helmet>
        <title>Hello World</title>
        <link rel="canonical" href="https://www.tacobell.com/" />
      </Helmet>
      <h1>Hello World</h1>
    </MonProd>
  </HelmetProvider>
)

ReactDOM.hydrate(app, document.getElementById('root'))
*/

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.register();


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
