import React, { Component } from 'react'
import PropTypes from 'prop-types'
import osmaps from '../functions/osmaps.js';


class MapPopup extends Component {

  constructor(props) {
    super(props);
    this.state = {
      active: false,
      linkedMarker: null,
      content: null
    }
  }

  static getDerivedStateFromProps(newProps, prevState) {
    if ( newProps.linkedMarker !== prevState.linkedMarker ) {
      return {
        active: newProps.active,
        linkedMarker: newProps.linkedMarker,
        content: newProps.content
      }
    }

    return null
  }

  componentDidMount() {
  }

  componentDidUpdate(newProps, prevState) {
  }

  render() {
    const { active, content } = this.state
    const { index, onClick } = this.props

    return (
      <div id="popup" className={`${active && 'active'}`}>
        <div id="popup-content">
          {content}
        </div>

        <button id="popup-closer">{/*onClick={() => onClick(index)}>*/}
        </button>
      </div>
    )
  }
}

// PropTypes
MapPopup.propTypes = {
  state: PropTypes.arrayOf(
    PropTypes.shape({
      linkedMarker: PropTypes.number.isRequired,
      active: PropTypes.bool.isRequired,
      content: PropTypes.string
    })
  )
}

export default MapPopup
