import React, { useState, useEffect } from 'react'
import { NavLink } from 'react-router-dom'
import PropTypes from 'prop-types'
import Icon from './Icon'
import osmaps from '../functions/osmaps.js'
import parse from 'html-react-parser'


export default function ProdBloc(props) {

  const values = props.marker_feature.values_
  const [active, setActive] = useState(false)

  useEffect(() => {
    setActive(props.active)
  }, [active])

  return (
    <div className={`prodbloc ${values.type}`}>
      <div className="prodhead">
        <div className="title">
          <h3>{values.name}</h3>
          {values.dist && <strong>à {values.dist}kms</strong>}
        </div>
        <div className="buttons">
          <button
            onClick={() => props.onClick(props.map, props.marker_feature)}
            title="Voir sur la carte">
            <Icon icon="locator" title={`Voir ${values.name} sur la carte`} />
          </button>
        </div>
      </div>
      {values.metier && <span className="metier">{values.metier}</span>}
      {(values.prods || values.opts) &&
        <div className='prodTags'>
          {values.prods &&
            values.prods.map((prod, index) =>
              <span className='tag'>{prod}</span>
            )
          }
          {values.opts &&
            values.opts.map((opt, index) =>
              <span className='tag'>{opt}</span>
            )
          }
        </div>
      }
      {values.ville &&
        <p>
          {values.adr && values.adr}
          <br />
          {values.cp && values.cp} {values.ville && values.ville}
        </p>
      }

      {values.desc &&
        <p>{parse(values.desc)}</p>
      }
      {values.dispos &&
        <p>
          <strong>Dispos</strong>
          {parse(values.dispos)}
        </p>
      }
      {values.url &&
        <NavLink to={`${values.url}`} title={`Voir ${values.name}`} className="prodlink">
          {(values.type==="producteur")
            ?
            <>
              <strong>Voir la fiche du producteur</strong>
              <Icon icon="fleche-longue" />
            </>
            :
            <>
              <strong>Voir la fiche de l'adresse</strong>
              <Icon icon="fleche-longue" />
            </>
          }
        </NavLink>
      }
    </div>
  )
}
