// Librairies React
import React, { useState, useEffect } from 'react'
import { NavLink, useLocation } from 'react-router-dom'
// import PropTypes from 'prop-types'
// import HTMLink from '../components/HTMLink'
import Icon from '../components/Icon'

export default function Header(props) {
  // const [location, setLocation] = useState(localStorage.getItem('position'))
  const [context, setContext] = useState("")

  // Identifie sur quelle page on est
  const router_location = useLocation()
  useEffect(() => {
    // ga.send(["pageview", router_location.pathname]);
    console.log("changed location", router_location.pathname)
    document.getElementsByTagName("body")[0].classList.add("loadin")

    let new_context = ""
    if ( router_location ) {
      switch (router_location.pathname) {
        case "/":
          new_context = "home"
          document.getElementsByTagName("body")[0].classList.remove("recherche")
        break;
        case "/recherche/":
          new_context = "recherche"
          document.getElementsByTagName("body")[0].classList.remove("home")
        break;
        default:
          new_context = ""
      }
    }
    setContext(new_context)
    console.log("CONTEXTE : " + new_context)
    if ( new_context ) {
      document.getElementsByTagName("body")[0].classList.add(new_context)
    }
  }, [router_location])

  // Menu accessible
  const goto = (e, cible) => {
    e.preventDefault()

    const elt = document.getElementById(cible)
    elt.scrollIntoView()
  }

  // Menu toggle
  const toggleMenu = (e) => {
    e.preventDefault()
		document.getElementsByTagName("body")[0].classList.toggle("menu-active")
  }


  // Map toggle
  const toggleMap = (e) => {
    e.preventDefault()
    const body_classes = document.getElementsByTagName("body")[0].classList
    // Ouverture du bloc de recherche
    if ( !body_classes.contains("searchin") ) {
      window.scrollTo(0, 0) // Scroll to top

      if ( context === "recherche" ) {
        document.getElementById("site-main").setAttribute("style", "padding-top:"+(document.getElementById("mapform").clientHeight+60)+'px')
      }
    }
    else {
      document.getElementById("site-main").removeAttribute("style")
    }
		body_classes.toggle("searchin")
  }

  // Close menu au clic dans le menu
  const closeMenu = (e) => {
    document.getElementsByTagName("body")[0].classList.remove("menu-active")
  }


  // render du composant
  return (
    <>
      <header id="header" className="site-header" role="banner" itemScope itemType="http://schema.org/WPHeader">
    		<ul className="print-hidden">
    			<li><a href="#site-navigation" onClick={e => goto(e, 'site-navigation')}>Aller au menu</a></li>
    		 	<li><a href="#site-main" onClick={e => goto(e, 'site-main')}>Aller au contenu</a></li>
    		</ul>

        <NavLink to="/" id="logo_link" itemProp="url">
          <img src={`${process.env.REACT_APP_URI}/images/logo-mon-producteur.svg`}
               width="150" height="64" alt="Mon-Producteur.com" />
        </NavLink>

        <nav id="site-navigation" role="navigation" itemScope itemType="http://schema.org/SiteNavigationElement">
          <ul id="menu-menu-principal" className="main-menu">
            <li className="menu-item menu-home">
              <NavLink to="/" itemProp="url" onClick={closeMenu}>
                <Icon icon='menu-home' />
                <span itemProp="name">Accueil</span>
              </NavLink>
            </li>
            <li className="menu-item">
              <NavLink to="/recherche/" itemProp="url" onClick={closeMenu}>
                <Icon icon='menu-loupe' />
                <span itemProp="name">Recherche avancée</span>
              </NavLink>
            </li>
            <li className="menu-item">
              <NavLink to="/au-rythme-des-saisons/" itemProp="url" onClick={closeMenu}>
                <Icon icon='menu-saisons' />
                <span itemProp="name">Au rythme des saisons</span>
              </NavLink>
            </li>
            <li className="menu-item">
              <NavLink to="/nos-valeurs/" itemProp="url" onClick={closeMenu}>
                <Icon icon='menu-coeur' />
                <span itemProp="name">Nos valeurs</span>
              </NavLink>
            </li>
            {/*
            <li className="menu-item">
              <NavLink to="/compte/" itemProp="url" onClick={closeMenu}>
                <Icon icon='menu-compte' />
                <span itemProp="name">Connexion / Inscription</span>
              </NavLink>
            </li>
            */}
            <li className="menu-facebook">
              <a href="https://www.facebook.com" target="_blank" rel="noreferrer" >
                <Icon icon="menu-facebook" />
                <span>Rejoignez notre communauté</span>
              </a>
            </li>
          </ul>

          <Icon class="logo" icon="logo" />
          <Icon class="bg-logo" icon="logo" />
        </nav>

        <div id="toggles">
          <button id="toggle-search" onClick={toggleMap}>
            <Icon icon='menu-loupe' />
            <span itemProp="name">Recherche</span>
          </button>

          {/*
          <NavLink to="/compte/" itemProp="url">
            <Icon icon='menu-compte' />
            <span itemProp="name">Mon compte</span>
          </NavLink>
          */}

    			<button id="toggle-menu" onClick={toggleMenu}>
    				<span>Menu</span>
            <strong>
              <span></span>
    			  </strong>
    			</button>
        </div>
      </header>
    </>
  )
}
