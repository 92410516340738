// Librairies
import React, { useState, useEffect } from 'react'
import HTMLink from '../components/HTMLink'
import PropTypes from 'prop-types'
import useLoadDatas from '../hooks/useLoadDatas'
import InscriProducteurForm from '../components/InscriProducteurForm'
import HelmetRender from '../layouts/HelmetRender'
import Breadcrumbs from '../layouts/Breadcrumbs'
import Content from '../components/Content'
import NotFound from '../components/NotFound'
import functions from '../functions/functions.js'
import parse from 'html-react-parser'

// Composant
export default function InscriProducteur() {

  const slug = "inscription-producteur"
  const [state, setState] = useState({
    isLoading: true,
    isError: false,
    jsonld: null,
    datas: null
  })

  const routeDatas = 'post?slug='+slug
  const [fetchDatas] = useLoadDatas()

  useEffect( () => {
    async function asyncFetch() {
      const data = await fetchDatas("page", {slug: slug})

      if ( data.error ) {
        setState({
          ...state,
          isLoading: false,
          isError: true
        })
      }
      else {
        setState({
          ...state,
          isLoading: false,
          jsonld: data.jsonld,
          datas: data.content
        })
      }
    }
    asyncFetch()

  }, [routeDatas])


  return (
    <main id="site-main">
      <ul className="todolist enter">
        <li>Contenu de présentation</li>
        <li>Vérifier étapes, bugs, etc.</li>
        <li>Tester formulaire avec plusieurs checkboxes ou radios</li>
        <li>Sauvegarder toutes les étapes</li>
        <li>Gérer étape de fin</li>
        <li>Sauvegarder inscription en producteur "brouillon"</li>
      </ul>
      <Breadcrumbs datas={state.jsonld && state.jsonld.breadcrumb} />

      {!state.isLoading &&
        <>
          {(state.isError || !state.jsonld) // Si pas de résultats de la route
            ?
            <NotFound id={slug} />
            :
            <>
              <HelmetRender json={state.jsonld} />
              <h1 className='enter'>
                {state.datas.title && parse(state.datas.title)}
              </h1>
              <div className='entry-content enter'>
                {state.datas.content && parse(state.datas.content, {a: HTMLink})}
                {state.datas.blocks && <Content blocks={state.datas.blocks} />}
              </div>
              <div className='enter'>
                <InscriProducteurForm />
              </div>
            </>
          }
        </>
      }
    </main>
  )
}
