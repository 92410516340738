import React, { Component } from 'react'
import PropTypes from 'prop-types'

class Dashboard extends Component {

  constructor(props) {
    super(props);

    // Controller du fetch
    this.controller = new window.AbortController()
  }

  static getDerivedStateFromProps(newProps, prevState) {
    return null
  }

  componentDidMount() {
    return null
  }

  componentDidUpdate(newProps, prevState) {
    return null
  }

  render() {
    return (
      <div id="">
        Bonjour...,
        <br />
        Ici, on insèrera le dashboard
      </div>
    )
  }
}


// PropTypes
Dashboard.propTypes = {
  state: PropTypes.arrayOf(
    PropTypes.shape({
      lat: PropTypes.number,
      lng: PropTypes.number
    })
  )
}

export default Dashboard
