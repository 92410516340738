// Librairies
import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import HTMLink from '../components/HTMLink'
// import PropTypes from 'prop-types'
import useLoadDatas from '../hooks/useLoadDatas'
import HelmetRender from '../layouts/HelmetRender'
import Breadcrumbs from '../layouts/Breadcrumbs'
import Content from '../components/Content'
import NotFound from '../components/NotFound'
import functions from '../functions/functions.js'
import parse from 'html-react-parser'

// Composant
export default function Produit() {

  // Paramètres d'url
  const { produits, type, produit } = useParams()
  const slug = produit

  const [datas, setDatas] = useState(null)

  // Chargement des données
  const [isLoading, setIsLoading] = useState(true)
  const [isError, setIsError] = useState(false)

  const routeDatas = 'post?slug='+slug
  const [fetchDatas] = useLoadDatas()

  useEffect( () => {
    async function asyncFetch() {
      const data = await fetchDatas("produit", {produit: slug})

      if ( data.error ) {
        setIsError(true)
      }
      else {
        setDatas({
          jsonld: data.jsonld,
          content: data.content
        })
      }

      setIsLoading(false)
    }
    asyncFetch()
  }, [routeDatas])

  return (
    <main id="site-main">
      <ul className="todolist">
        <li>Afficher producteurs à proximité (geolocation)</li>
      </ul>

      {!isLoading &&
        <>
          {isError // Si pas de résultats de la route
            ?
            <NotFound id={slug} />
            :
            <>
              <HelmetRender json={datas.jsonld} />
              <Breadcrumbs datas={datas.jsonld.breadcrumb} />
              <h1>
                {datas.content.title && parse(datas.content.title, {a: HTMLink})}
              </h1>
              <div className='entry-content'>
                {datas.content.content && parse(datas.content.content, {a: HTMLink})}
                {datas.content.blocks && <Content blocks={datas.content.blocks} />}
              </div>
            </>
          }
        </>
      }
    </main>
  )
}

// PropTypes
// Produit.propTypes = {
//   state: PropTypes.arrayOf(
//     PropTypes.shape({
//       id: PropTypes.number.isRequired,
//       prevId: PropTypes.number,
//       datas: PropTypes.object.isRequired,
//       isLoading: PropTypes.bool.isRequired
//     }).isRequired
//   )
// }
//
// export default Produit
