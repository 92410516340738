import React from 'react'
import NotFound from '../components/NotFound'

class PageNotFound extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      id: null,
      prevId: null,
    }
  }

  /* Compare le nouvel état du composant avec son ancien état */
  static getDerivedStateFromProps(newProps, prevState) {
    if ( newProps.id !== prevState.prevId ) {
      console.log('404 SHOULD UPDATE!', newProps.id, prevState.prevId)
      return {
        id: newProps.id,
        prevId: prevState.id
      }
    }
    return null
  }


  componentDidMount() {
    // Supprime la classe de chargement
    document.body.classList.remove("loadin")
  }


  render() {
    return (
      <main id="site-main">
        <NotFound />
      </main>
    )
  }
}
export default PageNotFound
