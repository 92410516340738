/* eslint no-undef: 0 */ // --> OFF
import React, { useState, useEffect, useReducer } from 'react'
import PropTypes from 'prop-types'
// https://alligator.io/react/react-autocomplete/
import ProdBloc from './ProdBloc'
import MapPopup from './MapPopup'
import Loader from './Loader'
import osmaps from '../functions/osmaps.js'

import {Projection, transform, fromLonLat, toLonLat, METERS_PER_UNIT} from 'ol/proj';

export default function Map(props) {

  let mapmove_type = 0

  const [state, setState] = useState({
                              mapLoaded: false,
                              map: null,
                              refreshOnMove: false,
                              canRefresh: false,
                              currentMarkers: [],
                              selectedMarker: null,
                              popupContent: null,
                              location: {
                                id: (props.id ? props.id : ''),
                                lat: (props.lat ? props.lat : ''), // '46.227638'
                                lng: (props.lng ? props.lng : ''), // '2.213749000000007'
                                nom: (props.nom ? props.nom : '')
                              },
                              rayon: 30,
                              produits: [],
                              selectedProduits: []
                            })

  useEffect( () => {
    async function asyncFetch() {
      if ( props.prods ) {
        return await loadProdsOnMap(props.lat, props.lng, props.prods)
      }
      else if ( props.lat && props.lng ) {
        return await loadMap(props.lat, props.lng)
      }
      return
    }
    asyncFetch()
  }, [props])

  const displayPopup = (map, marker_feature) => {
    const popupState = osmaps.showPopup(map, marker_feature) // marker_id, active, content
    setState({...state,
              selectedMarker: popupState.marker_id,
              popupContent: popupState.content})
  }

  const closePopup = (e) => {
    osmaps.hidePopup()
    setState({...state,
              selectedMarker: null,
              popupContent: ''})
  }

  /*
  activeRefresh = (e) => {
    e.preventDefault()

    // Si le refreshMove est false, alors on l'active et on centre la map
    if ( !state.refreshOnMove ) {
      // Centre la carte et recharge les markers
      osmaps.centerOnMap(state.map, this)
    }

    // Met à jour l'état du composant
    this.setState({refreshOnMove: (state.refreshOnMove ? false : true)})
  }
  */

  const refresh = (e) => {
    e.preventDefault()

    // Centre la carte et recharge les markers
    osmaps.centerOnMap(state.map, this)

    setState({...state,
              canRefresh: false})
  }

  const loadProdsOnMap = (lat, lng, prods) => {
    console.log("MAP LOAD PRODS")

    //const mapComponent = this
    const filtres = null

    const mapObj = osmaps.initMap(this, lat, lng, state.rayon, null)
    const markers = mapObj.map.getLayers().array_[2].getSource()
    let currentMarkers = []

    // Supprime les markers affichés
    markers.clear()

    { prods.map((prod) => {
        const marker = osmaps.createMarker(prod)
        //allMarkers.push(marker)
        currentMarkers.push(marker)
        markers.addFeature(marker)
      })
    }

    console.log("mapLoaded", state.mapLoaded)

    // Met à jour l'état du composant après la map
    setState({...state,
              // [mapLoaded]: true,
              map: mapObj.map,
              currentMarkers: currentMarkers
             })
  }

  const loadMap = (lat, lng, loadMarkers=false) => {
    console.log("MAP INIT")
    const {mapLoaded, location, rayon} = state
    //const mapComponent = this
    const filtres = null

    console.log(this)

    const renderedMap = osmaps.renderMap(this, lat, lng, rayon, filtres, loadMarkers)

    console.log(renderedMap)
    // setState(renderedMap) // format: {map, currentMarkers}
  }

  return (
    <div id="osmap" className="fullGrid enter">
      <div className="map_container">
        {state.canRefresh &&
          <button type="button" onClick={refresh} className="btn refresh-map">
            Rechercher dans cette zone...
          </button>
        }
        <div id="map" className="map"></div>
        <MapPopup
          key='mapopup'
          active={state.selectedMarker ? true : false}
          linkedMarker={state.selectedMarker}
          content={state.popupContent}
          index='mapopup'
          onClick={e => closePopup()}
          />
      </div>

      <div className="listprods">
        { (!state.currentMarkers || state.currentMarkers.length < 1) ?
          "Pas de producteur correspondant."
          :
          state.currentMarkers.map(marker => {
              console.log('marker', marker)
              const param_obj = {map: state.map, marker_feature: marker}
              return <ProdBloc
                        key={`prodbloc-${marker.values_.id}`}
                        active={(state.selectedMarker===marker.values_.id) ? true : false}
                        onClick={e => displayPopup(state.map, marker)}
                        {...param_obj} />
            }
          )
        }
      </div>
    </div>
  )
}
