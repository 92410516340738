import Map from 'ol/Map'
import View from 'ol/View'
import Overlay from 'ol/Overlay'
import TileLayer from 'ol/layer/Tile'
import Control from 'ol/control/Control'
import {Projection, transform, fromLonLat, toLonLat, METERS_PER_UNIT} from 'ol/proj'
import OSM from 'ol/source/OSM'
import Stamen from 'ol/source/Stamen.js'
import VectorLayer from 'ol/layer/Vector'
import VectorSource from 'ol/source/Vector'
import Feature from 'ol/Feature'
import {Point, Circle} from 'ol/geom'
import {easeOut} from 'ol/easing.js'
import {containsXY} from 'ol/extent.js'
import TileJSON from 'ol/source/TileJSON'
import osmJSON from '../datas/klokantech-basic.json'
import {Icon, Style, Circle as CircleStyle, Fill, Stroke} from 'ol/style'

// Médias
import img_center from '../assets/images/center.png'
import img_marker from '../assets/images/marker.png'
import img_marker_adresse from '../assets/images/marker.png'


// Style du point central
const center_style = new Style({
  image: new Icon({
    anchor: [0.5, 0.5],
    anchorXUnits: 'fraction',
    anchorYUnits: 'fraction',
    src: img_center,
    scale: .5,
    imgSize: [32, 32],
    crossOrigin: 'anonymous'
  })
})
// Style pour cacher du cercle de recherche
const circle_style = new Style({
  fill: new Fill({
    color: 'rgba(255, 255, 255, 0)'
  }),
  stroke: new Stroke({
    color: 'rgba(255, 255, 255, 0)',
    width: 0
  }),
  image: new CircleStyle({
    radius: 30,
    fill: new Fill({
      color: 'rgba(255, 255, 255, 0)'
    }),
    stroke: new Stroke({
      color: 'rgba(255, 255, 255, 0)',
      width: 0
    })
  })
})
const area_style = new Style({
  fill: new Fill({
    color: 'rgba(255, 255, 255, 0.6)'
  }),
  stroke: new Stroke({
    color: '#319FD3',
    width: 1
  }),
  image: new CircleStyle({
    radius: 5,
    fill: new Fill({
      color: 'rgba(255, 255, 255, 0.6)'
    }),
    stroke: new Stroke({
      color: '#319FD3',
      width: 1
    })
  })
})
const marker_style = new Style({
  image: new Icon({
    anchor: [.5, 0],
    anchorOrigin: 'bottom-left',
    anchorXUnits: 'fraction',
    anchorYUnits: 'fraction',
    src: img_marker,
    scale: .5,
    imgSize: [73, 68],
    crossOrigin: 'anonymous'
  })
})
const marker_style_adresse = new Style({
  image: new Icon({
    anchor: [.5, 0],
    anchorOrigin: 'bottom-left',
    anchorXUnits: 'fraction',
    anchorYUnits: 'fraction',
    src: img_marker,
    scale: .5,
    imgSize: [73, 68],
    crossOrigin: 'anonymous'
  })
})


// Projection de la Map
const projFrom = new Projection("EPSG:4326")
// const projFrom = new Projection({code: "EPSG:4326", units: 'm'})
const projTo = new Projection("EPSG:900913")
// const projTo = new Projection({code: "EPSG:900913", units: 'm'})


const osmaps = {
  // Fonction commune d'initialisation de toutes les maps du site
  initMap: function(component, lat, lng, rayon, adresses=null) {

    const currentMarkers = []
    const mapLayers = []

    // Layer de base
    const layer = new TileLayer({
      //source: new OSM()
      source: new Stamen({
        layer: 'terrain'
      })
      // source: new TileJSON({
      //   url: 'http://osm-liberty.lukasmartinelli.ch/style.json',
      //   crossOrigin: 'anonymous'
      //   //tileJSON: osmJSON
      // })
    })
    mapLayers.push(layer)

    // Position du marker
    const position = transform(fromLonLat([lng, lat]), projFrom, projTo)

    console.log("----------------------------INIT MAP")

    // Calque de la zone de recherche (si pas d'adresses précisées en parametre)
    const areaCircle = new VectorSource()
    const areaLayer = new VectorLayer({
      source: areaCircle,
      style: area_style
    })
    if ( !adresses ) {
      mapLayers.push(areaLayer)
    }

    const markers = new VectorSource()
    if ( adresses ) {
      {Object.values(adresses).map((adresse) => {
          const data = {
            id: adresse.id,
            lat: adresse.lat,
            lng: adresse.lng,
            nom: adresse.name,
            type: adresse.type,
            slug: adresse.slug,
            url: adresse.url,
            metier: adresse.metier,
            desc: adresse.intro,
            dispos: adresse.dispos,
            adr: adresse.adr,
            cp: adresse.cp,
            ville: adresse.ville,
            prods: adresse.prods,
            opts: adresse.opts,
            dist: null
          }
          const marker = osmaps.createMarker(data)

          currentMarkers.push(marker)
          markers.addFeature(marker)
        })
      }
    }
    const markersLayer = new VectorLayer({
      source: markers,
      updateWhileAnimating: true,
      updateWhileInteracting: true
    })
    mapLayers.push(markersLayer)

    // Popup showing the position the user clicked
    const popup = new Overlay({
      id: 'popup',
      element: document.getElementById('popup'),
      positioning: 'bottom-center',
      offset: [0, -55]
    })

    // Création de la Map
    const map = new Map({
      target: 'map', // (adresses ? 'prodmap' : 'map'),
      overlays: [popup],
      layers: mapLayers,
      renderer: 'canvas',
      view: new View({
        //projection: projection,
        center: position,
        zoom: 10
      })
      // controls: controls
    })

    // Met à jour la taille de la map si elle change
    // map.updateSize()

    // Zoom et positionnement de la map
    if ( adresses ) {
      // Centrer sur les markers s'il y a plus d'1 marker
      if ( Object.keys(adresses).length > 1 ) {
        const extent = markersLayer.getSource().getExtent()
        map.getView().fit(extent, map.getSize())
      }
    }
    else {
      // Crée la zone de recherche
      //https://openlayers.org/en/latest/examples/layer-extent.html
      const origin_feature = osmaps.createLocator(areaCircle, lat, lng, rayon)

      const extent = areaLayer.getSource().getExtent()
      map.getView().fit(extent, map.getSize())
    }

    // Détection du clic sur la map
    map.on('click', function(e) {
      // hasFeatureAtPixel
      const marker_feature = map.forEachFeatureAtPixel( e.pixel,
        function(feature, layer) {
          return feature
        },
        10 // hitTolerance
      )

      if ( marker_feature
           && marker_feature.getId() !== 'origin_feature'
           && marker_feature.getId() !== 'center_feature' ) {
        // Afficher la popup
        osmaps.showPopup(map, marker_feature)

      } else {
        // Cacher la popup
        osmaps.hidePopup()
      }
    })

    // Cacher la popup
    document.getElementById("popup-closer").onclick = function(e) {
      osmaps.hidePopup()
    }

    // Déplacements sur la carte
    // map.on('movestart', function(e) {
    //   console.log('movestart')
    //   // Détection du type de déplacement
    //   // Si pan => mapmove_type ok
    //   // Si zoom ou clic marker => mapmove_type none
    //
    //   // e.map.getView().getInteracting() : true si pan ou zoom
    //   component.mapmove_type = e.map.getView().getZoom()
    // })
    // map.on('moveend', function(e) {
    //   console.log('moveend')
    //   console.log(e.map.getView().getInteracting())
    //   // Si zoom actif, on n'applique le traitement
    //   if ( component.mapmove_type !== e.map.getView().getZoom() ) {
    //     return
    //   }
    //   /*
    //   if ( component.state.mapLoaded && component.state.refreshOnMove ) {
    //     // Centre la carte et recharge les markers
    //     osmaps.centerOnMap(this, component)
    //   }
    //   */
    //
    //   // Si pan, l'utilisateur se voit proposer la possibilité de recentrer la carte et les markers
    //   // if ( component.state.mapLoaded && !component.state.canRefresh ) {
    //   if ( component.state.mapLoaded && !component.state.canRefresh ) {
    //     component.setState({canRefresh: true})
    //   }
    //   else if ( !component.state.mapLoaded ) {
    //     component.setState({mapLoaded: true})
    //   }
    // })

    // Mise à jour du champ ville de MapForm à l'initialisation de la Map
    osmaps.getCity(map, component)

    return {map: map, currentMarkers: currentMarkers}
  },



  // Centre la carte et recharge les markers
  centerOnMap: function(map, component) {
    const new_center = map.getView().getCenter()

    const layerSource = map.getLayers().array_[1].getSource()

    const center_feature = layerSource.getFeatureById('center_feature')
    console.log(center_feature)
    center_feature.getGeometry().setCoordinates([new_center[0], new_center[1]])

    // Centre la zone de recherche
    const origin_feature = layerSource.getFeatureById('origin_feature')
    origin_feature.getGeometry().setCenter([new_center[0], new_center[1]])

    // Mise à jour du centre
    const lnglat = toLonLat([new_center[0], new_center[1]])

    // Créer fetch avec Promise, pour attendre les résultats avant de traiter la suite
    const filtres = ""
    console.log('rayon', component.state.rayon)
    const markers = osmaps.loadMarkers(component, map, lnglat[1], lnglat[0], component.state.rayon, filtres)

    // Mise à jour du champ ville de MapForm
    osmaps.getCity(map, component)
  },



  // Fonction affichant la carte sur la Home
  renderMap: function(component, lat, lng, rayon, filtres, loadMarkers) {
    console.log("----------------------------RENDER MAP")

    const mapObj = osmaps.initMap(component, lat, lng, rayon, null)
    let currentMarkers = []

    // S'il y a des markers à charger
    if ( loadMarkers ) {
      currentMarkers = osmaps.loadMarkers(component, mapObj.map, lat, lng, rayon, filtres)
    }

    // Retourne la map
    return {map: mapObj.map, currentMarkers: currentMarkers}
  },



  // Fonction affichant la carte sur une fiche producteur
  renderMapProducteur: function(component, adresses) {
    console.log("----------------------------RENDER MAP PRODUCTEUR")

    const mapObj = osmaps.initMap(component, adresses[0].lat, adresses[0].lng, null, adresses)

    // Retourne la map
    return mapObj // format: {map, currentMarkers}

  },



  updateMap: function(component, lat, lng, rayon, filtres) {
    console.log("----------------------------UPDATE MAP")
    const map = component.state.map
    const radius = (rayon * 1000 * METERS_PER_UNIT['m'])

    // Met à jour le rayon
    const layerSource = map.getLayers().array_[1].getSource()
    const origin_feature = layerSource.getFeatureById('origin_feature')
    origin_feature.getGeometry().setCenter( transform(fromLonLat([lng, lat]), projFrom, projTo) )
    origin_feature.getGeometry().setRadius( radius )

    // Et rechercher les markers
    console.log("Prévoir fetch sur les nouveaux markers et le filtre des markers en stock")

    // Ajuste le zoom de la map
    const extent = layerSource.getExtent()
    map.getView().fit(extent, map.getSize())

    osmaps.loadMarkers(component, map, lat, lng, rayon, filtres)

    return
  },


  // Récupère la ville correspondante
  getCity: function(map, mapComponent) {
    const new_center = map.getView().getCenter()
    const lnglat = toLonLat([new_center[0], new_center[1]])
    fetch(`https://api-adresse.data.gouv.fr/reverse/?lat=${lnglat[1]}&lon=${lnglat[0]}`)
      .then((resp) => resp.json())
      .then(function(response) {

        const objLocations = []
        response.features.map( (location) => {
          // Si ville
          objLocations.push({
            value: location.properties.citycode,
            label: location.properties.city+" ("+location.properties.postcode+")",
            lat: location.geometry.coordinates[1],
            lng: location.geometry.coordinates[0]
          })
        })

        // Mise à jour du contenu
        mapComponent.setState({
          location: {
            id: objLocations[0].value,
            lat: objLocations[0].lat,
            lng: objLocations[0].lng,
            nom: objLocations[0].label
          }
        })

        return
      })
      .catch(function(error) {
        console.warn(JSON.stringify(error))
      })
  },


  // Charge les markers
  loadMarkers: function(component, map, lat, lng, rayon, filtres) {

    const markers = map.getLayers().array_[2].getSource()

    // Fetch selon les paramètres
    //console.log(`http://localhost:8888/monprod2019/wp-json/monprod/proxiprods?lat=${lat}&lng=${lng}&rad=${rayon}&filt=${filtres}`)
    try {
      fetch(`${process.env.REACT_APP_WP}/wp-json/monprod/proxiprods?lat=${lat}&lng=${lng}&rad=${rayon}&filt=${filtres}`)
        .then((resp) => resp.json())
        .then(function(datas) {
          console.log("----------------------------PROXIPRODS")
          console.log(datas)
          //const allMarkers = component.state.markers
          const currentMarkers = []

          // Supprime les markers affichés
          markers.clear()

          { datas.map((data) => {
              const marker = osmaps.createMarker(data)
              //allMarkers.push(marker)
              currentMarkers.push(marker)
              markers.addFeature(marker)
            })
          }

          // Met à jour l'état du composant après la map
          component.setState({
            mapLoaded: true,
            //markers: markers.getFeatures(),
            currentMarkers: currentMarkers
          })
          console.log(markers.getFeatures())
          console.log("currentMarkers", currentMarkers)
          //return markers.getFeatures()
        })
        .catch(function(error) {
          console.warn(JSON.stringify(error))
        })
    }
    catch (error) {
      console.log(error)
    }
  },


  // Crée le locator avec un cercle de rayon
  createLocator: function(layer, lat, lng, rayon) {

    const radius = (rayon * 1000 * METERS_PER_UNIT['m'])

    const center = transform(fromLonLat([lng, lat]), projFrom, projTo)

    // Point central
    const center_feature = new Feature({
      geometry: new Point(center)
    })
    center_feature.setId('center_feature')
    center_feature.setStyle(center_style)
    layer.addFeature(center_feature)

    // Cercle de périmètre de recherche
    const circle = new Circle(center, radius)
    const origin_feature = new Feature({
      geometry: circle
    })
    origin_feature.setId('origin_feature')
    origin_feature.setStyle(circle_style)
    layer.addFeature(origin_feature)
    return
  },


  // Crée un marker
  // Attention : lat et lng doivent être des nombres
  createMarker: function(data) {

    const marker_feature = new Feature({
      id: data.id,
      geometry: new Point(transform(fromLonLat([data.lng, data.lat]), projFrom, projTo)),
      name: data.nom,
      type: data.type,
      slug: data.slug,
      url: data.url,
      metier: data.metier,
      desc: data.desc,
      dispos: data.dispos,
      adr: data.adr,
      cp: data.cp,
      ville: data.ville,
      prods: data.prods,
      opts: data.opts,
      dist: data.dist
    })
    marker_feature.setId(data.id)

    console.log(data.type)
    // marker_feature.setStyle( (data.type === "producteur" ? marker_style : marker_style_adresse) )
    marker_feature.setStyle( marker_style )

    return marker_feature
  },
  clickMarker: function(marker) {
    console.log('click marker')
  },
  createPopup: function(id_marker) {
    console.log(id_marker)
    return
  },
  showPopup: function(map, marker_feature) {
    // Contenu de la popup
    document.getElementById('popup-content').innerHTML = "<strong>"+marker_feature.values_.name+"</strong>";
    document.getElementById('popup-content').innerHTML += "<p>"+marker_feature.values_.metier+"</p>";
    // document.getElementById('popup-content').innerHTML += <NavLink to={`${marker_feature.values_.url}`} title={`Voir ${marker_feature.values_.name}`}>
    //                                                         <span>Voir la fiche</span><Icon icon="fleche-longue" />
    //                                                       </NavLink>
    document.getElementById('popup').classList.add("active");

    // Positionnement de la popup
    var geometry = marker_feature.getGeometry();
    var coords = geometry.getCoordinates();
    map.getOverlayById('popup').setPosition(coords);

    // Centre sur le marker
    map.getView().animate({
       center: coords,
       duration: 400
    })

    return {marker_id: marker_feature.values_.id, active:true, content: marker_feature.values_.name}
  },
  hidePopup: function() {
    //document.getElementById('popup').style.display = 'none';
    document.getElementById('popup').classList.remove("active");

    return {active:false}
  }
}

export default osmaps
