// Librairies React
import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'
import PropTypes from 'prop-types'
import Icon from '../components/Icon'

// Composant
class Footer extends Component {

  // render du composant
  render() {
    return (
      <footer id="footer" className="footer">
        <div className="fullGrid">
          <div className="grid33">
            <NavLink to="/" id="logo_footer" itemProp="url">
              <img src={`${process.env.REACT_APP_URI}/images/logo-mon-producteur-blanc.svg`}
                   width="150" height="64" alt="Mon-Producteur.com" />
            </NavLink>
          </div>
          <nav className="grid33" role="navigation" itemScope itemType="http://schema.org/SiteNavigationElement">
            <ul>
              <li className="menu-item">
                <NavLink to="/qui-sommes-nous/" itemProp="url">
                  <span itemProp="name">Qui sommes-nous ?</span>
                </NavLink>
              </li>
              <li className="menu-item">
                <NavLink to="/contact/" itemProp="url">
                  <span itemProp="name">Nous contacter</span>
                </NavLink>
              </li>
              <li className="menu-item">
                <NavLink to="/" itemProp="url">
                  <span itemProp="name">Politique de confidentialité</span>
                </NavLink>
              </li>
              <li className="menu-item">
                <NavLink to="/" itemProp="url">
                  <span itemProp="name">Conditions Générales d'Utilisation</span>
                </NavLink>
              </li>
              <li className="menu-item">
                <NavLink to="/" itemProp="url">
                  <span itemProp="name">Plan du site</span>
                </NavLink>
              </li>
              <li className="menu-item">
                <NavLink to="/" itemProp="url">
                  <span itemProp="name">Crédits</span>
                </NavLink>
              </li>
            </ul>
          </nav>
          <div className="grid33">
            <strong>Vous êtes producteur ?<br />Rejoignez-nous !</strong>
            <p>Inscrivez-vous pour profiter gratuitement d’une visibilité sur internet.</p>
            <NavLink to="/inscription-producteur/" className="btn" itemProp="url">
              <span itemProp="name">Inscription</span>
            </NavLink>
            <a href="https://www.facebook.com" className="footer-facebook" target="_blank" rel="noreferrer">
              <Icon icon="menu-facebook" />
              <span>Rejoignez notre<br />communauté !</span>
            </a>
          </div>
        </div>
        <p className="copyright">Mon-producteur.com©{new Date().getFullYear()} - tous droits réservés.</p>
      </footer>
    )
  }
}

// PropTypes
Footer.propTypes = {
  state: PropTypes.arrayOf(
    PropTypes.shape({
      active: PropTypes.bool.isRequired
    })
  )
}

export default Footer
