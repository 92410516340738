// Librairies React
import React, { Fragment, Component } from 'react'
import PropTypes from 'prop-types'

// Composant
class SwitchField extends Component {

  constructor(props) {
    super(props);

    this.inputRef = React.createRef()
  }

  componentDidMount(newProps, prevState) {
    // Force le focus par défaut (retour d'erreurs notamment)
    if ( this.props.focused ) {
      this.inputRef.current.focus()
    }
  }

  componentDidUpdate(newProps, prevState) {
    // Force le focus par défaut (retour d'erreurs notamment)
    if ( this.props.focused ) {
      this.inputRef.current.focus()
    }
  }

  // render du composant
  render() {
    const {name, type, classe, label, placeholder, options, required,
           value, link, focused, fieldError,
           onFocus, onBlur, onChange, onClick} = this.props

    return (
      <Fragment key={name}>
        {(() => {
          switch(name) {
            // Cas particulier de la RGPD
            case "rgpd":
              return (
                <div className={`mp-rgpd ${classe} ${fieldError ? 'error' : ''} ${(value) ? "active" : ""}`}>
                  {fieldError && <em>Veuillez accepter les conditions.</em>}
                  <label htmlFor={name}>Accepter <a href={`${process.env.REACT_APP_BASE}${link}`} onClick={(e) => onClick(e, link)}>notre RGPD</a>...</label>
                  <input type="checkbox"
                    ref={this.inputRef}
                    id={name}
                    name={name}
                    checked={value}
                    onChange={(e) => onChange(e)}
                    />
                </div>
              )

            // Autres champs
            default:
              switch(type) {

                // Select
                case "select":
                  return (
                    <div className={`mp-field ${classe} ${fieldError ? 'error' : ''} ${(focused || value) ? "active" : ""}`}>
                      {fieldError && <em>Veuillez compléter ce champ.</em>}
                      <label htmlFor={name}>{label}{required ? " *" : ""}</label>
                      <select
                        ref={this.inputRef}
                        id={name}
                        name={name}
                        value={value}
                        onFocus={(e) => onFocus(e)}
                        onBlur={(e) => onBlur(e)}
                        onChange={(e) => onChange(e)}
                        >
                        <option key={`obj-none`} value="">{placeholder}</option>
                        { options.map((option, index) =>
                          <option key={`obj-${index}`} value={option}>{option}</option>
                        )}
                      </select>
                    </div>
                  )

                // Textarea
                case "textarea":
                  return (
                    <div className={`mp-field ${classe} ${fieldError ? 'error' : ''} ${(focused || value) ? "active" : ""}`}>
                      {fieldError && <em>Veuillez compléter ce champ</em>}
                      <label htmlFor={name}>{label}{required ? " *" : ""}</label>
                      <textarea
                        ref={this.inputRef}
                        id={name}
                        name={name}
                        placeholder={placeholder}
                        value={value}
                        onFocus={(e) => onFocus(e)}
                        onBlur={(e) => onBlur(e)}
                        onChange={(e) => onChange(e)}
                        />
                    </div>
                  )
                // Default: text, email...
                default:
                  return (
                    <div className={`mp-field ${classe} ${fieldError ? 'error' : ''} ${(focused || value) ? "active" : ""}`}>
                      {fieldError && <em>Veuillez compléter ce champ</em>}
                      <label htmlFor={name}>{label}{required ? " *" : ""}</label>
                      <input type={type}
                        ref={this.inputRef}
                        id={name}
                        name={name}
                        placeholder={placeholder}
                        value={value}
                        onFocus={(e) => onFocus(e)}
                        onBlur={(e) => onBlur(e)}
                        onChange={(e) => onChange(e)}
                        />
                    </div>
                  )
              }
            }
          })()
        }
      </Fragment>
    )
  }
}

export default SwitchField
