// Librairies React
import React, { Component } from 'react'
import PropTypes from 'prop-types'

// Médias
import loader from '../assets/images/loader.gif';

// Composant
class Loader extends Component {

  constructor(props) {
    super(props);
    this.state = {
      active: false
    }
  }

  // render du composant
  render() {
    return (
      <div id={this.props.id ? this.props.id : ''} className={`loader ${this.props.classe ? this.props.classe : ''}`}>
        <img src={loader} width="100" height="100" alt="Contenu en cours de chargement..." />
      </div>
    )
  }
}

// PropTypes
Loader.propTypes = {
  state: PropTypes.arrayOf(
    PropTypes.shape({
      active: PropTypes.bool.isRequired
    })
  )
}

export default Loader
