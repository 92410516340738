import React, { useState, useEffect, useRef } from 'react'
// import PropTypes from 'prop-types'
import useLoadDatas from '../hooks/useLoadDatas'
import Select from 'react-select'
import AsyncSelect, { useAsync } from 'react-select/async'
import Slider from 'rc-slider'
import 'rc-slider/assets/index.css'
import Icon from './Icon'
import geolocation from '../functions/geolocation.js'
import osmaps from '../functions/osmaps.js'
import functions from '../functions/functions.js'

export default function MapFormRecherche(props) {

  const filtres = JSON.parse(localStorage.getItem('filtres'))
  const [activeFiltres, setActiveFiltres] = useState(false)
  const [produits, setProduits] = useState([])
  const [featProduits, setFeatProduits] = useState([])
  const [selectProduits, setSelectProduits] = useState(filtres ? filtres.produits : [])
  let selecteurProduits = useRef()
  const [rayon, setRayon] = useState(filtres ? filtres.rayon : 30)
  const [options, setOptions] = useState(filtres ? filtres.options : {
    "cueillir": false,
    "livraison": false,
    "panier": false,
    "commande": false
  })
  const [mapActive, setMapActive] = useState(false)
  const [location, setLocation] = useState(filtres ? filtres.location : {
    id: '',
    lat: '',
    lng: '',
    nom: ''
  })
  let current_timeout

  // Chargement des données
  const [isLoading, setIsLoading] = useState(true)
  const [isError, setIsError] = useState(false)

  const routeDatas = process.env.REACT_APP_WP+'/wp-json/monprod/produits'
  const [fetchDatas] = useLoadDatas()

  useEffect( () => {
    // Fetch selon les paramètres
    try {
      fetch(routeDatas)
        .then((resp) => resp.json())
        .then(function(datas) {
          console.log("----------------------------PRODUITS")
          const allProds = []
          const featProds = []
          { datas.map((data) => {
              allProds.push({
                id: data.id,
                value: data.slug,
                label: data.nom
              })
              if ( data.saison ) {
                featProds.push({
                  id: data.id,
                  value: data.slug,
                  label: data.nom
                })
              }
            })
          }
          allProds.push({
            id: "gibier",
            value: "gibier faisan perdrix",
            label: "Gibiers"
          })
          console.log(allProds)
          console.log(featProds)
          setProduits([...allProds])
          setFeatProduits([...featProds])
        })
        .catch(function(error) {
          console.warn(JSON.stringify(error))
        })
    }
    catch (error) {
      console.log(error)
    }
  }, [routeDatas])


  // Ajoute un produit
  const addProduct = (e, produit) => {
    e.preventDefault()

    // Si le produit n'est pas déjà sélectionné
    if ( selectProduits.find(obj => obj.value === produit.value) === undefined ) {
      let newSelectProduits = selectProduits.concat(produit)
      selecteurProduits.current.setValue(newSelectProduits)

      // Ajoute au select de produits
      setSelectProduits([...newSelectProduits])
    }
  }

  // const handleChangeProducts = (opt) => {
  //   console.log(opt)
  //   const newSelectProduits = selectProduits.concat({value:opt.value, label:opt.label})
  //
  //   // Ajoute au select de produits
  //   setSelectProduits([...newSelectProduits])
  // }

  // Propose les données
  // https://adresse.data.gouv.fr/api
  // https://github.com/JedWatson/react-select/issues/2877
  const asyncLocations = (inputValue, callback) => {

    console.log(inputValue)
    clearTimeout(current_timeout) // Supprime le précédent Timeout

    if ( inputValue && inputValue.length >= 3 ) {
      current_timeout = setTimeout(() => {

        const coords = `&lat=${location.lat}&lon=${location.lng}`

        fetch(`https://api-adresse.data.gouv.fr/search/?q=${inputValue}${coords}&limit=10`)
          .then((resp) => resp.json())
          .then(function(response) {
            // console.log(response.features)
            // console.log(response.features.coordinates[0], response.features.coordinates[1])
            // console.log(response.properties.citycode)
            // console.log(response.properties.city, response.properties.label, response.properties.name)
            const objLocations = []
            response.features.map( (loc) => {
              // Si ville
              if ( loc.properties.type === "municipality" || loc.properties.type === "locality" ) {
                objLocations.push({
                  value: loc.properties.citycode,
                  label: loc.properties.city+" ("+loc.properties.postcode+")",
                  lat: loc.geometry.coordinates[1],
                  lng: loc.geometry.coordinates[0]
                })
              }
            })

            // Mise à jour des options
            callback( objLocations )
          })
          .catch(function(error) {
            console.warn(JSON.stringify(error))
          })
      }, 1000)
    }
    return null
  }

  const onGeolocate = (e) => {
    console.log("onGeolocate")

    const component = this

    geolocation.getCurrentPosition()
      .then(position => {
        console.log("getCurrentPosition")
        const current_location = {lat: position.coords.latitude, lng: position.coords.longitude}
        localStorage.setItem('position', JSON.stringify(current_location))
        console.log(current_location)

        // Récupère la ville correspondante
        fetch(`https://api-adresse.data.gouv.fr/reverse/?lat=${position.coords.latitude}&lon=${position.coords.longitude}`)
          .then((resp) => resp.json())
          .then(function(response) {
            const city = response.features[0]
            setLocation({
              id: city.properties.citycode,
              lat: city.geometry.coordinates[1],
              lng: city.geometry.coordinates[0],
              nom: city.properties.city+" ("+city.properties.postcode+")"
            })
            return
          })
          .catch(function(error) {
            console.warn(JSON.stringify(error))
          })
      })
      .catch(error => {
        console.log(error.message)
        // geolocation.stopPosition(error)
      })
  }

  const mapFormSubmit = (e, location, selectedProduits, rayon, options) => {
    e.preventDefault()

    console.log("MAP handleSubmit")
    console.log(rayon)

    // Sauvegarde dans localStorage
    const params = {
      produits: selectedProduits,
      location: location,
      rayon: rayon,
      options: options
    }
    console.log(params)
    localStorage.setItem('filtres', JSON.stringify(params))

    // Met à jour l'état du composant
    // setLocation({
    //   id: location.id,
    //   lat: location.lat,
    //   lng: location.lng,
    //   nom: location.nom
    // })
    // setSelectProduits([...selectedProduits])

    props.newSubmit(params)

    // Soumet la recherche
  }

  const handleChange = (opt) => {
    console.log("handleChange", opt)
    setLocation({
      id: opt.value,
      lat: opt.lat,
      lng: opt.lng,
      nom: opt.label
    })
  }

  const handleOptions = (e) => {
    console.log(e.target.value, e.target.checked)
    options[e.target.value] = e.target.checked
    console.log(options)
    setOptions({...options})
  }

  const toggleFiltres = (active) => {
    setActiveFiltres(active)
  }

  // Effet à l'ouverture des filtres
  useEffect( () => {
    if ( document.body.classList.contains("searchin") ) {
      document.getElementById("site-main").setAttribute("style", "padding-top:"+(document.getElementById("mapform").clientHeight+60)+'px')
    }
  }, [activeFiltres])

  return (
    <div id="mapform" className={mapActive ? 'active' : ''}>
      <div className="titleform">
        {props.context === "home"
          ?
          <h1>Trouvez les producteurs autour de chez vous</h1>
          :
          <strong>Trouvez les producteurs autour de chez vous</strong>
        }
      </div>

      <form className=""
        onSubmit={(e) => mapFormSubmit(e, location, selectProduits, rayon, options)}
        >
        <div className="mp-field map-produits">
          <label htmlFor="produits">Produit(s) recherché(s) :</label>
          <p><em>Permettre recherche par produits et catégories</em></p>
          <Select
            ref={selecteurProduits}
            id="produits"
            name="produits"
            placeholder="Que recherchez-vous ?"
            defaultValue={selectProduits}
            onChange={setSelectProduits}
            isMulti
            options={produits}
            className="basic-multi-select"
            classNamePrefix="select"
            />
          {featProduits &&
            <div className="feats">
              {featProduits.map( (featProd) =>
                <FeatButton
                  key={featProd.id}
                  label={featProd.label}
                  value={featProd.value}
                  onClick={(e) => addProduct(e, featProd)}
                  />
                )
              }
            </div>
          }
        </div>
        <div className="bloc-geolocation">
          <div className="mp-field map-location">
            <label htmlFor="location">Localisation :</label>
            <AsyncSelect
              id="location"
              name="location"
              placeholder="Votre ville"
              cacheOptions={true}
              value={location.id ? { value: location.id, label: location.nom, lat: location.lat, lng: location.lng } : '' }
              loadOptions={(inputValue, callback) => asyncLocations(inputValue, callback)}
              onChange={handleChange}
              noOptionsMessage={() => null }
              />
          </div>
          <button type="button" className="btn-geolocate" onClick={onGeolocate} title='Me géolocaliser'>
            <Icon icon='geolocate' title='Me géolocaliser' />
          </button>
        </div>
        <div className={`mp-field-more ${activeFiltres ? "active" : ""}`}>
          <button type="button" className="filtres-open" onClick={() => toggleFiltres(true)}>
            <span>Filtrer</span>
            <Icon icon='filtres' />
          </button>
          <div className="mp-more-content">
            <button type="button" className="filtres-close" onClick={() => toggleFiltres(false)}>
              <Icon icon='close' />
            </button>
            <div className="map-rayon">
              <label htmlFor="rayon">Distance : {rayon}kms</label>
              <Slider
                range
                id="rayon"
                name="rayon"
                minValue={0}
                maxValue={100}
                value={rayon}
                onChange={value => setRayon(value[0])}
                />
              <span className="rayon-start">0km</span>
              <span className="rayon-end">100kms</span>
            </div>
            <div className={`mp-checkbox ${options.cueillir && "checked"}`}>
              <label htmlFor="mp-cueillir">A cueillir soi-même</label>
              <input
                id="mp-cueillir"
                name="options[]"
                type="checkbox"
                value="cueillir"
                defaultChecked={options.cueillir}
                onChange={e => handleOptions(e)} />
            </div>
            <div className={`mp-checkbox ${options.livraison && "checked"}`}>
              <label htmlFor="mp-livraison">Livraison possible</label>
              <input
                id="mp-livraison"
                name="options[]"
                type="checkbox"
                value="livraison"
                defaultChecked={options.livraison}
                onChange={e => handleOptions(e)} />
            </div>
            <div className={`mp-checkbox ${options.panier && "checked"}`}>
              <label htmlFor="mp-panier">Paniers</label>
              <input
                id="mp-panier"
                name="options[]"
                type="checkbox"
                value="panier"
                defaultChecked={options.panier}
                onChange={e => handleOptions(e)} />
            </div>
            <div className={`mp-checkbox ${options.commande && "checked"}`}>
              <label htmlFor="mp-commande">Sur commande</label>
              <input
                id="mp-commande"
                name="options[]"
                type="checkbox"
                value="commande"
                defaultChecked={options.commande}
                onChange={e => handleOptions(e)} />
            </div>
          </div>
        </div>
        <div className="">
          <button type="submit" className="btn">Rechercher</button>
        </div>
      </form>
    </div>
  )
}

const FeatButton = ({label, value, onClick}) => (
  <button className='btntolink' type="button" onClick={(e) => onClick(e, value, label)}>
    {label}
  </button>
)
