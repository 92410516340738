import React, { Fragment } from 'react'
import { NavLink } from 'react-router-dom'
import HTMLink from '../components/HTMLink'
import Carousel from '../components/Carousel'
import Icon from '../components/Icon'
import functions from '../functions/functions.js'
import parse from 'html-react-parser'

export default function Content(props) {
  const switchBlockType = (index, block) => {
    switch (block.name) {
      case 'produits-saison':
        const prods = JSON.parse(block.json)
        return <div className={`bloc-${block.name}${block.className ? " "+block.className : ""}`}>
                 <Carousel id='slider-saison' items={prods} />
               </div>

      case 'categories':
        const cats = JSON.parse(block.json)
        return <div className={`bloc-${block.name}${block.className ? " "+block.className : ""}`}>
                 <ul className='fullGrid'>
                  {cats.map( cat =>
                    <li key={cat.id} className='grid16'>
                      <NavLink to={cat.url}>
                        <Icon icon={`cat-${cat.slug}`} />
                        <span>{parse(cat.name)}</span>
                      </NavLink>
                    </li>
                  )}
                 </ul>
               </div>

      case 'media-text':
        return <div className={`bloc-${block.name}${block.className ? " "+block.className : ""}`}>
                 <div className={`fullGrid${block.position==="right" ? " revGrid" : ""}`}>
                   <div className='media'>{parse(block.media)}</div>
                   <div className='contenu'>
                     {block.blocks.map( (inner_block, j) =>
                       <Fragment key={`bloc-${index}-${j}`}>
                         {switchBlockType(j, inner_block)}
                       </Fragment>
                     )}
                   </div>
                  </div>
                </div>

      case 'buttons':
        console.log("buttons", block.name)
        return <div className={`bloc-${block.name}${block.className ? " "+block.className : ""}`}>
                 {block.blocks.map( (inner_block, j) =>
                   <Fragment key={`bloc-${index}-${j}`}>
                     {switchBlockType(j, inner_block)}
                   </Fragment>
                 )}
                </div>

      case 'button':
        const block_button = parse(block.html)[1].props
        const block_link = block_button.children.props
        console.log("button", block_link)

        return <div className={`bloc-${block.name}${block_button.className ? " "+block_button.className : ""}`}>
                 <NavLink to={functions.cleanURL(block_link.href)} className={block_link.className}>
                   {block_link.children}
                 </NavLink>
               </div>

      default:
        console.log("default", block)
        return <>{block.html && parse(block.html, {a: HTMLink})}</>
    }
  }

  return (
    <>
      {props.blocks &&
        <>
          {props.blocks.map( (block, i) =>
            <Fragment key={`bloc-${i}`}>
              {switchBlockType(i, block)}
            </Fragment>
          )}
        </>
      }
    </>
  )
}
