// Librairies React
import React, { useEffect } from 'react'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import PropTypes from 'prop-types'


// Composant
export default function HelmetRender(props) {

  const helmetContext = {}

  useEffect( () => {
    console.log("HELMET RENDER")
    console.log(props.json)
  }, [props])

  return (
    <HelmetProvider context={helmetContext}>
      <Helmet>
        <title>{props.json.name}</title>
        <meta name="description" content={props.json.description} />
        <meta name="theme-color" content="#000" />
        <meta property="og:title" content={props.json.name} />
        <meta property="og:description" content={props.json.description} />
        <meta property="og:image" content="" />
        <meta property="og:locale" content="fr_FR" />
        <meta property="og:site_name" content={props.json.copyrightHolder.name} />
        <link rel="canonical" href={props.json.relatedLink} />
        { props.json &&
          <script type="application/ld+json">
            {JSON.stringify(props.json)}
          </script>
        }
      </Helmet>
    </HelmetProvider>
  )
}

// PropTypes
// HelmetRender.propTypes = {
//   state: PropTypes.arrayOf(
//     PropTypes.shape({
//       active: PropTypes.bool.isRequired
//     })
//   )
// }
//
// export default HelmetRender
