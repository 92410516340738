const functions = {
  // renderContent: function(item) {
  //   // Changement de l'état
  //   return item.content.rendered
  // },

  // Nettoie une URL complète pour l'adapter au Router React
  cleanURL: function(url) {
    // console.log(
    //   url,
    //   process.env.REACT_APP_URI,
    //   process.env.REACT_APP_BASE,
    //   process.env.REACT_APP_WP
    // )
    url = url.replace(`${process.env.REACT_APP_URI}${process.env.REACT_APP_BASE}`, "") // Nettoie l'url de l'app
    url = url.replace(`${process.env.REACT_APP_WP}/`, "") // Nettoie l'url du WP

    return (url.length > 0 ? "/" : "")+( url.charAt(url.length-1) === "/" ? url : url+"/" ) // Force le Trailing Slash
  },

  //
  // // Charge des données asynchrones avant dispatch vers bon composant
  // loadAsyncDatas: function(component, signal, type, params) {
  //   let page = ''
  //   let slug = ''
  //   let fetch_url = ''
  //
  //   document.body.classList.remove("home", "searchin") // Supprime la classe .home et .searchin du body
  //
  //   switch (type) {
  //     // Composants Home - Page (Normale, France, Produits)
  //     case 'page':
  //       slug = (params ? params.slug : 'home')
  //       fetch_url = `post?slug=${slug}`
  //     break;
  //
  //     // Composant Recherche
  //     case 'recherche':
  //       console.log("recherche")
  //       console.log(params)
  //       if ( params ) {
  //         page = (params.page ? params.page : '')
  //         slug = type+(page && "-"+page)
  //
  //         fetch_url = `rechercheprods?lat=${params.location.lat}&lng=${params.location.lng}&ray=${params.rayon}&filt=${JSON.stringify(params.produits)}&page=${page}`
  //       }
  //       else {
  //         fetch_url = `rechercheprods`
  //       }
  //     break;
  //
  //     // Composant ListProducteurs
  //     case 'listproducteurs':
  //       page = (params.page ? params.page : '')
  //       slug = (params.departement ? params.departement : params.region)+(page && "-"+page)
  //
  //       fetch_url = `listprods?reg=${params.region}&dep=${(params.departement ? params.departement : '')}&page=${page}`
  //     break;
  //
  //     // Composant Producteur
  //     case 'producteur':
  //       slug = params.slug
  //       fetch_url = `prod?slug=${slug}`
  //     break;
  //
  //     // Composant ListProduits
  //     case 'listproduits':
  //       slug = params.type
  //       fetch_url = `listproduits?slug=${slug}`
  //     break;
  //
  //     // Composant Produit
  //     case 'produit':
  //       slug = params.produit
  //       fetch_url = `produit?slug=${slug}`
  //     break;
  //
  //     default:
  //       return null
  //   }
  //
  //   const myHeaders = new Headers()
  //   myHeaders.append("Content-Type", "application/json")
  //   myHeaders.append("Access-Control-Allow-Origin", "*")
  //   myHeaders.append("Access-Control-Allow-Credentials", "true")
  //   myHeaders.append("Access-Control-Allow-Methods", "GET,HEAD,OPTIONS,POST,PUT")
  //   myHeaders.append("Access-Control-Allow-Headers", "Origin, X-Requested-With, Content-Type, Accept, Authorization")
  //
  //   try {
  //     component._asyncRequest =
  //       fetch(`${process.env.REACT_APP_WP}/wp-json/monprod/${fetch_url}`, {
  //         method: 'GET',
  //         mode: 'cors',
  //         //headers: myHeaders,
  //         cache: 'default', // *default, no-cache, reload, force-cache, only-if-cached
  //         //credentials: "include", // include, *same-origin, omit
  //         referrer: "*client", // no-referrer, *client
  //         redirect: "follow", // manual, *follow, error
  //         signal: signal
  //       })
  //       .then(response => {
  //         if (response.ok) {
  //           return response.clone().json()
  //         } else {
  //           throw Error(response.statusText)
  //           component.setState({ error:true, isLoading: false })
  //         }
  //       })
  //       .then(json_datas => {
  //         component.setState({
  //           id: slug,
  //           prevId: slug,
  //           jsonld: json_datas["jsonld"],
  //           datas: json_datas["content"],
  //           error: (json_datas["error"] ? true : false),
  //           isLoading: false
  //         })
  //         component._asyncRequest = null;
  //
  //         // Gère l'affichage après le chargement
  //         window.scrollTo(0, 0) // Scroll to top
  //         setTimeout( function() {
  //           document.body.classList.remove("loadin") // Supprime la classe .loadin du body
  //           if ( slug === 'home' ) {
  //             document.body.classList.add("home") // Ajoute la classe .home du body
  //           }
  //         }, 400)
  //       })
  //       .catch(error => {
  //         component.setState({ error: true, isLoading: false })
  //         console.warn(JSON.stringify(error))
  //       })
  //   }
  //   catch (error) {
  //     //console.warn(error)
  //   }
  //   return
  // },



  // Charge des données asynchrones pour une popin (notamment RGPD dans les formulaires)
  loadPopinContent: function(component, signal, page) {
    const fetch_url = `popin?page=${page}`

    try {
      component._asyncRequest =
        fetch(`${process.env.REACT_APP_WP}/wp-json/monprod/${fetch_url}`, {
          method: 'GET',
          mode: 'cors',
          cache: 'default', // *default, no-cache, reload, force-cache, only-if-cached
          referrer: "*client", // no-referrer, *client
          redirect: "follow", // manual, *follow, error
          signal: signal
        })
        .then(response => {
          if (response.ok) {
            return response.clone().json()
          } else {
            throw Error(response.statusText)
            component.setState({ error:true, isLoading: false })
          }
        })
        .then(json_datas => {
          component.setState({
            popin: {
              content: json_datas.content, // Contenu de la popin
              display: true
            }
          })
          component._asyncRequest = null;
        })
        .catch(error => {
          console.log("error", error)
          //console.warn(JSON.stringify(error))
        })
    }
    catch (error) {
      //console.warn(error)
    }
    return
  },



  // Charge des données asynchrones avant dispatch vers bon composant
  loadMoreProds: function(component, signal, params, page) {

    const fetch_url = `listprodspage?reg=${params.region}&dep=${(params.departement ? params.departement : '')}&page=${page}`

    try {
      component._asyncRequest =
        fetch(`${process.env.REACT_APP_WP}/wp-json/monprod/${fetch_url}`, {
          method: 'GET',
          mode: 'cors',
          cache: 'default', // *default, no-cache, reload, force-cache, only-if-cached
          referrer: "*client", // no-referrer, *client
          redirect: "follow", // manual, *follow, error
          signal: signal
        })
        .then(response => {
          if (response.ok) {
            return response.clone().json()
          } else {
            throw Error(response.statusText)
            component.setState({ error:true, moreLoading: false })
          }
        })
        .then(json_datas => {
          return {
            prods: json_datas["prods"],
            page: json_datas["page"]
          }
        })
        .catch(error => {
          console.log("error", error)
          //console.warn(JSON.stringify(error))
        })
    }
    catch (error) {
      //console.warn(error)
    }
    return
  },

  // Sauvegarde un formulaire
  saveFormDatas: function(component, fields) {

    // console.log("--------------------- saveFormDatas")
    // Formattage des données en formData
    const formDatas  = new FormData();
    for(const name in fields) {
      formDatas.append(name, fields[name])
    }

    try {
      component._asyncRequest =
        fetch(`${process.env.REACT_APP_WP}/wp-json/monprod/saveform`, {
          method: 'POST',
          mode: 'cors',
          body: formDatas,
          cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
          // credentials: "omit", // include, *same-origin, omit
          referrer: "*client", // no-referrer, *client
          //signal: signal
        })
        .then(response => {
          if (response.ok) {
            return response.json()
          } else {
            component.setState({
              status: "error",
              returnMessage: "Erreur lors de la soumission du formulaire...",
              isLoading: false
            })
          }
        })
        .then(json_datas => {
          // Réinitialisation des champs selon le formulaire
          let form_fields = {}
          switch(formDatas.form) {
            case "Contact":
              form_fields = {
                nom: "",
                prenom: "",
                email: "",
                objet: "",
                message: "",
                rgpd: false
              }
            break;
            default:
          }

          // Mise à jour de l'état du formulaire
          component.setState({
            fields: form_fields,
            status: "succes",
            returnMessage: "Merci pour votre message. Nous vous répondons au plus vite.",
            isLoading: false
          })

          // Supprime le localStorage du formulaire
          localStorage.removeItem(formDatas.form)

          component._asyncRequest = null;
        })
        .catch(error => {
          component.setState({
            status: "error",
            returnMessage: "Erreur lors de la soumission du formulaire...",
            isLoading: false
          })
        })
    }
    catch (error) {
      //console.warn(error)
    }
    return
  },

  // Vérification des erreurs sur les champs
  // Retourne true s'il y a des erreurs
  checkErrors: function(component, etapes, step=0, isSubmit) {
    const errors = {}

    etapes[step-1].fields.map( field => {
      if ( field.required && !component.state.fields[field.name] ) {
        errors[field.name] = field.listError
      }
    })

    // Mise à jour après vérification
    if ( Object.keys(errors).length ) {
      component.setState({
        status: "error",
        fieldErrors: errors
      })
      return true
    }
    else {
      if ( isSubmit ) {
        component.setState({
          status: "",
          fieldErrors: {},
          returnMessage: "",
          isLoading: true
        })
      }
      else {
        component.setState({
          step: step+1,
          status: "",
          fieldErrors: {},
          returnMessage: ""
        })
      }
      return false
    }
  },

  // Formate les paramètres en tableau
  getParameters: function(params) {
    let results = {}
    params
      .substr(1)
      .split("&")
      .forEach(function (item) {
        const splitted_param = item.split("=");
        results[splitted_param[0]] = decodeURIComponent(splitted_param[1]);
      });
    return results;
  }
}

export default functions
