// Librairies React
import React, { Fragment, Component } from 'react'
import { NavLink } from 'react-router-dom'
import PropTypes from 'prop-types'
import functions from '../functions/functions.js'
import parse from 'html-react-parser'


// Composant
class Breadcrumbs extends Component {

  /*
  <nav id="nav-breadcrumbs" role="navigation">
    <ol id="breadcrumbs" itemscope="" itemtype="http://schema.org/BreadcrumbList">
      <li class="home" itemprop="itemListElement" itemscope="" itemtype="http://schema.org/ListItem">
        <a itemprop="item" href="https://www.ouestindustriescreatives.fr">Accueil</a>
        <meta itemprop="position" content="1">
      </li>
      <li itemprop="itemListElement" itemscope="" itemtype="http://schema.org/ListItem">
        <a itemprop="item" href="https://www.ouestindustriescreatives.fr/recherche-et-experimentation/">
          <span itemprop="name">Recherche et expérimentation</span>
        </a>
        <meta itemprop="position" content="2">
      </li>
    </ol>
  </nav>
  */
  // render du composant
  render() {
    const { datas } = this.props
    const lastIndex = datas && (datas.itemListElement.length-1)

    return (
      <>
      { datas &&
        <nav id="nav-breadcrumbs" className='enter' role="navigation">
          <ol id="breadcrumbs" className="innerWidth">
            { datas.itemListElement.map( (listItem, index) =>
              <li key={`bd-${listItem.position}`} className={listItem.position===1 ? 'home' : ''}>
              { (index !== lastIndex)
                  ?
                  <NavLink to={functions.cleanURL(Object.values(listItem.item)[0])}>{parse(listItem.item.name)}</NavLink>
                  :
                  <span>{parse(listItem.item.name)}</span>
                }
                <meta content={listItem.position} />
              </li>
            )}
          </ol>
        </nav>
      }
      </>
    )
  }
}

// PropTypes
Breadcrumbs.propTypes = {
  state: PropTypes.arrayOf(
    PropTypes.shape({
      active: PropTypes.bool.isRequired
    })
  )
}

export default Breadcrumbs
